import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const FinalOptions = () => {
  const { state: locationState } = useLocation();
  const [state, setState] = useState(locationState);
  const navigate = useNavigate();
  const [groupedOptions, setGroupedOptions] = useState({});
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleDelete = (option, optionType) => {
    const updatedState = { ...state };
    updatedState[optionType] = updatedState[optionType].filter(
      (item) => item !== option
    );
    setState(updatedState);
  };

  const handleButtonClick = async () => {
    navigate(`/item-selection`, {
      state: {
        ...state,
        groupedOptions: groupedOptions,
      },
    });
  };

  useEffect(() => {
    // Group options by their optionType when component mounts or state changes
    const options = Object.entries(state).reduce(
      (acc, [optionType, options]) => {
        if (Array.isArray(options)) {
          acc[optionType] = options.map((option) => ({ option, optionType }));
        }
        return acc;
      },
      {}
    );
    setGroupedOptions(options); // Update groupedOptions state
  }, [state]);

  return (
    <Container>
      {Object.entries(groupedOptions).some(([optionType, options]) =>
        options.some(
          ({ option }) => option !== locationState.selectedEventType.name
        )
      ) ? (
        <TableContainer
          component={Paper}
          style={{
            marginBottom: "40px",
            marginTop: "80px",
          }}
        >
          <Typography
            className="typography-4"
            align="center"
            style={{
              marginTop: "50px",
              marginBottom: "40px",
            }}
          >
            Please finalize your selections:
          </Typography>
          <Table style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    className={isMobile ? "typography-2" : "typography-4"}
                  >
                    Question
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={isMobile ? "typography-2" : "typography-4"}
                  >
                    Choice
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <Typography className="typography-4">Action</Typography> */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedOptions).map(
                ([optionType, options], index) =>
                  options.some(
                    ({ option }) =>
                      option !== locationState.selectedEventType.name
                  ) && (
                    <TableRow key={index}>
                      <TableCell style={{ minWidth: 150 }}>
                        <Typography className="typography-2">
                          {optionType.toLowerCase() === "partyfavoroptions"
                            ? "Party Favor"
                            : optionType.charAt(0).toUpperCase() +
                              optionType
                                .slice(1)
                                .toLowerCase()
                                .replace("options", "")}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ minWidth: 200 }}>
                        {options.map(({ option }, index) => {
                          if (option === locationState.selectedEventType.name) {
                            return null;
                          }
                          return (
                            <div key={index}>
                              <Typography
                                className={
                                  isMobile ? "typography-2" : "typography-2"
                                }
                              >
                                {option}
                              </Typography>
                            </div>
                          );
                        })}
                      </TableCell>
                      <TableCell style={{ minWidth: 50 }}>
                        {options.map(({ option }, index) => {
                          if (option === locationState.selectedEventType.name) {
                            return null;
                          }
                          return (
                            <div key={index}>
                              <Button
                                size="medium"
                                onClick={() => handleDelete(option, optionType)}
                              >
                                <Typography
                                  className={
                                    isMobile ? "typography-6" : "typography-2"
                                  }
                                >
                                  <DeleteIcon style={{ fontSize: "18px" }} />
                                </Typography>
                              </Button>
                            </div>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography
            className="typography-1"
            style={{
              marginTop: "100px",
              marginBottom: "40px",
            }}
          >
            We haven't received any options, keep in mind that without selected
            options, we may not be able to curate specific items.
          </Typography>
          <Typography
            className="typography-1"
            style={{
              marginTop: "20px",
              marginBottom: "40px",
            }}
          >
            Do you wish to continue?
          </Typography>
        </>
      )}
      <Button
        className="button-8"
        onClick={handleButtonClick}
        fullWidth
        style={{ marginBottom: "40px" }} // Adjust margin bottom
      >
        Continue
      </Button>
    </Container>
  );
};

export default FinalOptions;
