import GamesIcon from "@mui/icons-material/Games";

const partyFavorOptions = [
  {
    label: "Yes",
    icon: <GamesIcon />,
  },
  {
    label: "No",
    icon: <GamesIcon />,
  },
];

export default partyFavorOptions;
