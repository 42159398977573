import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import FinalOptions from "./FinalOptions.tsx";

const GenerateConcept = () => {
  const [generatedText, setGeneratedText] = useState("");
  const { state: locationState } = useLocation();

  useEffect(() => {
    document.body.style.overflowY = "visible";
    return () => {
      document.body.style.overflowY = "hidden";
    };
  }, [generatedText, locationState]);

  return (
    <>
      <FinalOptions />
    </>
  );
};

export default GenerateConcept;
