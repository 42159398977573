import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLayout from "./components/PageLayout.tsx";
import Home from "./components/Home.tsx";
import EventSelection from "./components/EventSelection.tsx";
import NavBar from "./components/NavBar.tsx";
import GenerateConcept from "./components/GenerateConcept.tsx";
import Atmosphere from "./components/Atmosphere.tsx";
import Seating from "./components/Seating.tsx";
import Beverages from "./components/Beverages.tsx";
import Interests from "./components/Interests.tsx";
import Interactive from "./components/Interactive.tsx";
import Decorations from "./components/Decorations.tsx";
import Age from "./components/Age.tsx";
import Catering from "./components/Catering.tsx";
import Desserts from "./components/Desserts.tsx";
import PartyFavors from "./components/PartyFavors.tsx";
import Colors from "./components/Colors.tsx";
import Personality from "./components/Personality.tsx";
import Destination from "./components/Destination.tsx";
import NotFound from "./components/NotFound.tsx";
import ItemSelection from "./components/ItemSelection.tsx";
import ReviewCart from "./components/ReviewCart.tsx";

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route
          path=""
          element={
            <PageLayout>
              <Home />
            </PageLayout>
          }
        />
        <Route
          path="/"
          element={
            <PageLayout>
              <Home />
            </PageLayout>
          }
        />
        {/* Define other routes... */}
        <Route path="*" element={<NotFound />} /> {/* 404 Route */}
        {/* 404 Route */}
        <Route
          path="/event-selection"
          element={
            <PageLayout>
              <EventSelection />
            </PageLayout>
          }
        />
        <Route
          path="/generate-concept"
          element={
            <PageLayout>
              <GenerateConcept />
            </PageLayout>
          }
        />
        <Route
          path="/item-selection"
          element={
            <PageLayout>
              <ItemSelection />
            </PageLayout>
          }
        />
        <Route
          path="/item-selection/review-cart"
          element={
            <PageLayout>
              <ReviewCart />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/age"
          element={
            <PageLayout>
              <Age />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/colors"
          element={
            <PageLayout>
              <Colors />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/decorations"
          element={
            <PageLayout>
              <Decorations />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/dessert"
          element={
            <PageLayout>
              <Desserts />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/destination"
          element={
            <PageLayout>
              <Destination />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/partyfavors"
          element={
            <PageLayout>
              <PartyFavors />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/personality"
          element={
            <PageLayout>
              <Personality />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/catering"
          element={
            <PageLayout>
              <Catering />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/atmosphere"
          element={
            <PageLayout>
              <Atmosphere />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/seating"
          element={
            <PageLayout>
              <Seating />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/beverages"
          element={
            <PageLayout>
              <Beverages />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/interests"
          element={
            <PageLayout>
              <Interests />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/interactive"
          element={
            <PageLayout>
              <Interactive />
            </PageLayout>
          }
        />
        <Route
          path="/event-selection/decorations"
          element={
            <PageLayout>
              <Decorations />
            </PageLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
