import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ReviewCart = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");

  useEffect(() => {
    document.body.style.overflowY = "visible";
  }, []);

  const handleNavigateBack = () => {
    navigate("/item-selection", {
      state: {
        ...locationState,
        generatedText: locationState.generatedText,
        cart: locationState.cart,
      },
    });
  };

  const getUniqueItemsWithQuantity = (cart) => {
    const uniqueItems = {};
    cart.forEach((item) => {
      if (!uniqueItems[item.name]) {
        uniqueItems[item.name] = { ...item, quantity: 1 };
      } else {
        uniqueItems[item.name].quantity++;
      }
    });
    return Object.values(uniqueItems);
  };

  const deleteItem = (index) => {
    const updatedCart = [...locationState.cart];
    updatedCart.splice(index, 1);
    navigate("/item-selection/review-cart", {
      state: {
        ...locationState,
        cart: updatedCart,
      },
    });
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "150px" }}>
        <Button
          className="button-2"
          color="primary"
          onClick={handleNavigateBack}
        >
          Back to Review Cart
        </Button>
      </Grid>
      <Grid item>
        <Typography className="typography-4" style={{ paddingTop: "50px" }}>
          Ready to Checkout? Here's your cart and purchase link.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <div style={{ padding: isTablet ? " 10px" : "0" }}>
          <TableContainer component={Paper} style={{ marginTop: "40px" }}>
            <Table style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className="typography-2">Item</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className="typography-2">
                      {isMobile ? "Info" : "Description"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className="typography-2">Quantity</Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography className="typography-2">Link</Typography>
                  </TableCell> */}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getUniqueItemsWithQuantity(locationState.cart).map(
                  (item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography className="typography-2">
                          <img
                            className="item-image"
                            src={item.image}
                            alt={"image"}
                            style={
                              isMobile
                                ? { width: "50px", height: "50px" }
                                : { width: "100px", height: "100px" }
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="typography-2">
                          {item.description.length > 10 && isMobile
                            ? item.description.slice(0, 15) + "..."
                            : item.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className="typography-2"
                          style={{ margin: "20px" }}
                        >
                          {item.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography className="typography-2">
                            {isMobile ? "Link" : "Amazon Link"}
                          </Typography>
                        </a>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteItem()}>
                          <DeleteIcon
                            style={{ fontSize: isMobile ? "20px" : "30px" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </>
  );
};

export default ReviewCart;
