
import GamesIcon from '@mui/icons-material/Games';

const atmosphereOptions = [
    { 
      label: 'Dancy', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Vibrant', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Glamorous', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Magical', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Chill', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Romantic', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Elegant', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Rustic', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Bohemian', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Vintage', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Tropical', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Modern', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Festive', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Whimsical', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Enchanted', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Sophisticated', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Intimate', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Garden', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Beach', 
      icon: <GamesIcon /> 
    },
    { 
      label: 'Industrial', 
      icon: <GamesIcon /> 
    }
];


  export default atmosphereOptions