import GamesIcon from '@mui/icons-material/Games';

const personalityOptions = [
    { 
      label: 'Confident', 
      icon: <GamesIcon />
    },
    { 
      label: 'Reserved', 
      icon: <GamesIcon />
    },
    { 
      label: 'Adventurous', 
      icon: <GamesIcon />
    },
    { 
      label: 'Analytical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Creative', 
      icon: <GamesIcon />
    },
    { 
      label: 'Optimistic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Pessimistic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Practical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Sensitive', 
      icon: <GamesIcon />
    },
    { 
      label: 'Assertive', 
      icon: <GamesIcon />
    },
    { 
      label: 'Ambitious', 
      icon: <GamesIcon />
    },
    { 
      label: 'Easygoing', 
      icon: <GamesIcon />
    },
    { 
      label: 'Independent', 
      icon: <GamesIcon />
    },
    { 
      label: 'Dependent', 
      icon: <GamesIcon />
    },
    { 
      label: 'Organized', 
      icon: <GamesIcon />
    },
    { 
      label: 'Disorganized', 
      icon: <GamesIcon />
    },
    { 
      label: 'Resilient', 
      icon: <GamesIcon />
    },
    { 
      label: 'Perfectionist', 
      icon: <GamesIcon />
    },
    { 
      label: 'Impulsive', 
      icon: <GamesIcon />
    },
    { 
      label: 'Introverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Extroverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Logical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Emotional', 
      icon: <GamesIcon />
    },
    { 
      label: 'Adaptable', 
      icon: <GamesIcon />
    },
    { 
      label: 'Structured', 
      icon: <GamesIcon />
    },
    { 
      label: 'Spontaneous', 
      icon: <GamesIcon />
    },
    { 
      label: 'Introverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Extroverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Logical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Emotional', 
      icon: <GamesIcon />
    },
    { 
      label: 'Adaptable', 
      icon: <GamesIcon />
    },
    { 
      label: 'Structured', 
      icon: <GamesIcon />
    },
    { 
      label: 'Spontaneous', 
      icon: <GamesIcon />
    },
    { 
      label: 'Reserved', 
      icon: <GamesIcon />
    },
    { 
      label: 'Charismatic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Introverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Extroverted', 
      icon: <GamesIcon />
    },
    { 
      label: 'Analytical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Creative', 
      icon: <GamesIcon />
    },
    { 
      label: 'Optimistic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Pessimistic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Practical', 
      icon: <GamesIcon />
    },
    { 
      label: 'Sensitive', 
      icon: <GamesIcon />
    },
  ];

export default personalityOptions;
