import GamesIcon from "@mui/icons-material/Games";

const beverageOptions = [
  {
    label: "Cocktails",
    icon: <GamesIcon />,
  },
  {
    label: "Variety",
    icon: <GamesIcon />,
  },
  {
    label: "No Booze",
    icon: <GamesIcon />,
  },
  {
    label: "Beer",
    icon: <GamesIcon />,
  },
  {
    label: "Wine",
    icon: <GamesIcon />,
  },
  {
    label: "Spirits",
    icon: <GamesIcon />,
  },
  {
    label: "Champagne",
    icon: <GamesIcon />,
  },
  {
    label: "Soda",
    icon: <GamesIcon />,
  },
  {
    label: "Juice",
    icon: <GamesIcon />,
  },
  {
    label: "Tea",
    icon: <GamesIcon />,
  },
  {
    label: "Coffee",
    icon: <GamesIcon />,
  },
  {
    label: "Punch Bowl",
    icon: <GamesIcon />,
  },
  {
    label: "Cider",
    icon: <GamesIcon />,
  },
  {
    label: "Smoothies",
    icon: <GamesIcon />,
  },
  {
    label: "Milkshakes",
    icon: <GamesIcon />,
  },
  {
    label: "Lemonade",
    icon: <GamesIcon />,
  },
  {
    label: "Hot Chocolate",
    icon: <GamesIcon />,
  },
  {
    label: "Bring Your Own",
    icon: <GamesIcon />,
  },
  {
    label: "Mocktails",
    icon: <GamesIcon />,
  },
];

export default beverageOptions;
