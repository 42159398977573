import GamesIcon from '@mui/icons-material/Games';

const interestOptions = [
    { 
      label: 'Basketball', 
      icon: <GamesIcon />
    },
    { 
      label: 'Baseball', 
      icon: <GamesIcon />
    },
    { 
      label: 'Soccer', 
      icon: <GamesIcon />
    },
    { 
      label: 'Tennis', 
      icon: <GamesIcon />
    },
    { 
      label: 'Golf', 
      icon: <GamesIcon />
    },
    { 
      label: 'Swimming', 
      icon: <GamesIcon />
    },
    { 
      label: 'Running', 
      icon: <GamesIcon />
    },
    { 
      label: 'Cycling', 
      icon: <GamesIcon />
    },
    { 
      label: 'Hiking', 
      icon: <GamesIcon />
    },
    { 
      label: 'Yoga', 
      icon: <GamesIcon />
    },
    { 
      label: 'Meditation', 
      icon: <GamesIcon />
    },
    { 
      label: 'Reading', 
      icon: <GamesIcon />
    },
    { 
      label: 'Writing', 
      icon: <GamesIcon />
    },
    { 
      label: 'Drawing', 
      icon: <GamesIcon />
    },
    { 
      label: 'Painting', 
      icon: <GamesIcon />
    },
    { 
      label: 'Photography', 
      icon: <GamesIcon />
    },
    { 
      label: 'Cooking', 
      icon: <GamesIcon />
    },
    { 
      label: 'Baking', 
      icon: <GamesIcon />
    },
    { 
      label: 'Music', 
      icon: <GamesIcon />
    },
    { 
      label: 'Dancing', 
      icon: <GamesIcon />
    },
    { 
      label: 'Singing', 
      icon: <GamesIcon />
    },
    { 
      label: 'Acting', 
      icon: <GamesIcon />
    },
    { 
      label: 'Crafting', 
      icon: <GamesIcon />
    },
    { 
      label: 'Gardening', 
      icon: <GamesIcon />
    },
    { 
      label: 'Traveling', 
      icon: <GamesIcon />
    },
    { 
      label: 'Volunteering', 
      icon: <GamesIcon />
    },
    { 
      label: 'Learning', 
      icon: <GamesIcon />
    },
    { 
      label: 'Teaching', 
      icon: <GamesIcon />
    },
    { 
      label: 'Film', 
      icon: <GamesIcon />
    },
    { 
      label: 'Theater', 
      icon: <GamesIcon />
    },
    { 
      label: 'History', 
      icon: <GamesIcon />
    },
    { 
      label: 'Science', 
      icon: <GamesIcon />
    },
    { 
      label: 'Technology', 
      icon: <GamesIcon />
    },
    { 
      label: 'Nature', 
      icon: <GamesIcon />
    },
    { 
      label: 'Animals', 
      icon: <GamesIcon />
    },
    { 
      label: 'Fashion', 
      icon: <GamesIcon />
    },
    { 
      label: 'Fitness', 
      icon: <GamesIcon />
    },
    { 
      label: 'Health', 
      icon: <GamesIcon />
    },
    { 
      label: 'Finance', 
      icon: <GamesIcon />
    },
    { 
      label: 'Entrepreneurship', 
      icon: <GamesIcon />
    }
];

export default interestOptions;
