
import GamesIcon from '@mui/icons-material/Games';

const dessertOptions = [
    { 
      label: 'Yes', 
      icon: <GamesIcon />, 
    },
     { 
      label: 'No', 
      icon: <GamesIcon />, 
    }
  ]

  export default dessertOptions