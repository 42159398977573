import GamesIcon from "@mui/icons-material/Games";

const cateringOptions = [
  {
    label: "Buffet Style",
    icon: <GamesIcon />,
  },
  {
    label: "Fine Dining",
    icon: <GamesIcon />,
  },
  {
    label: "Fast Food",
    icon: <GamesIcon />,
  },
  {
    label: "Potluck",
    icon: <GamesIcon />,
  },
  {
    label: "Family Style",
    icon: <GamesIcon />,
  },
  {
    label: "Barbecue",
    icon: <GamesIcon />,
  },
  {
    label: "Cocktails",
    icon: <GamesIcon />,
  },
  {
    label: "Food Trucks",
    icon: <GamesIcon />,
  },
  {
    label: "Finger Food",
    icon: <GamesIcon />,
  },
  {
    label: "Brunch",
    icon: <GamesIcon />,
  },
  {
    label: "Picnic Style",
    icon: <GamesIcon />,
  },
  {
    label: "Dessert Bar",
    icon: <GamesIcon />,
  },
  {
    label: "Ethnic Cuisine",
    icon: <GamesIcon />,
  },
  {
    label: "Tapas",
    icon: <GamesIcon />,
  },
  {
    label: "Gourmet Sandwiches",
    icon: <GamesIcon />,
  },
  {
    label: "Pizza Party",
    icon: <GamesIcon />,
  },
  {
    label: "Seafood Buffet",
    icon: <GamesIcon />,
  },
  {
    label: "Vegetarian/Vegan",
    icon: <GamesIcon />,
  },
  {
    label: "Hors d'oeuvres",
    icon: <GamesIcon />,
  },
  {
    label: "Dim Sum",
    icon: <GamesIcon />,
  },
];

export default cateringOptions;
