import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      width: '100%',
      borderColor: '#c19797', // Border color
      borderRadius: '15px',
    },
    '&:hover fieldset': {
      borderColor: '#c19797', // Hovered border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c19797', // Focused border color
    },
  },
  '& .MuiOutlinedInput-input': {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',  // Set the font family
    color: '#c19797', // Text color
    padding: '12px',
  },
  '& .MuiInputLabel-outlined': {
    color: '#c19797', // Label color
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: '#c19797', // Focused label color
  },
  '& .MuiInputAdornment-root': {
    color: '#c19797', // Icon color
  },
});

const CustomTextField = ({ searchTerm, handleSearchChange }) => {
  return (
    <StyledTextField
      variant="outlined"
      placeholder="Search events"
      value={searchTerm}
      onChange={handleSearchChange} 
      InputProps={{
        startAdornment: (
          <SearchIcon style={{ color: '#c19797', marginRight: '8px', fontSize: '30px' }} />
        ),
      }}
    />
  );
};

export default CustomTextField;
