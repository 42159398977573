export const eventConcepts = {
  Engagement: {
    small:
      "A romantic dinner on a private rooftop overlooking the city skyline, with live violin music and a personalized message in a bottle.",
    medium:
      "An intimate garden proposal surrounded by twinkling fairy lights and lanterns, with a surprise serenade from a local acoustic band.",
    large:
      "A lavish yacht cruise with friends and family, featuring champagne toasts, fireworks, and a grand gesture under the stars.",
  },

  "Bachelor Party": {
    small:
      "An adrenaline-packed day of skydiving followed by a private dinner at a gourmet steakhouse, complete with cigars and whiskey tasting.",
    medium:
      "A weekend camping trip in the wilderness with a bonfire, BBQ cookout, and outdoor activities like fishing, hiking, and paintball.",
    large:
      "A Las Vegas extravaganza with VIP club access, pool parties, a private poker tournament, and a limo tour of the Strip.",
  },

  Bachelorette: {
    small:
      "A pampering spa day with couples massages, facials, and champagne, followed by a sunset beach picnic with gourmet appetizers.",
    medium:
      "A wine tasting tour to multiple vineyards with a chauffeured limo, wine-paired lunch, and a private painting class at a vineyard.",
    large:
      "A glamorous rooftop cocktail party with live DJ, photo booth, and rooftop games like giant Jenga and bean bag toss.",
  },

  Housewarming: {
    small:
      "A cozy dinner at home with close friends, featuring a DIY pizza station and wine tasting.",
    medium:
      "A backyard BBQ with lawn games, a DIY cocktail bar, and a s'mores station around a fire pit.",
    large:
      "An open-house style event with food trucks, live music, and guided tours of the new home.",
  },

  Retirement: {
    small:
      "A serene weekend getaway to a secluded cabin in the mountains, with hiking, birdwatching, and stargazing.",
    medium:
      "A retirement party at a favorite local restaurant with a roast, speeches, and a slideshow of memorable moments.",
    large:
      "A cruise ship vacation with family and friends, featuring onboard activities, gourmet dining, and excursions at port stops.",
  },

  "Game Night": {
    small:
      "A cozy evening at home with a homemade dinner and classic board games by the fireplace.",
    medium:
      "A game tournament with multiple stations for different board games, accompanied by pizza, snacks, and prizes for winners.",
    large:
      "A rented venue with large-scale versions of popular board games, a DJ, and themed food and drinks.",
  },

  "Movie Night": {
    small:
      "A private screening of favorite movies in a home theater setup with gourmet popcorn, candy, and cozy blankets.",
    medium:
      "An outdoor movie night in the backyard with a projector, bean bags, and a themed concession stand serving movie snacks.",
    large:
      "A movie marathon event at a rented cinema with a selection of films, comfortable seating, and catering from a local restaurant.",
  },

  "Book Club": {
    small:
      "A cozy evening at home discussing the latest book selection over wine and cheese.",
    medium:
      "A book club meeting at a local coffee shop with a private room, featuring a guest author Q&A session and book signing.",
    large:
      "A book-themed cocktail party at a rented venue with literary-inspired decorations, book-themed cocktails, and a book swap.",
  },

  "Art Show": {
    small:
      "An intimate gallery tour with a private art curator, followed by a champagne reception and personalized artwork commission.",
    medium:
      "An art exhibition featuring local artists at a trendy gallery space, with wine tasting, live music, and an art auction.",
    large:
      "A community art fair showcasing various artists and mediums, with interactive art installations, workshops, and food trucks.",
  },

  "Wine Tasting": {
    small:
      "A private wine tasting experience at a local vineyard with a sommelier, featuring a selection of rare and aged wines paired with gourmet appetizers.",
    medium:
      "A guided wine tasting tour to multiple wineries in a wine region, with transportation, wine education sessions, and a gourmet lunch.",
    large:
      "A wine festival event featuring local wineries, live music, food vendors, and activities like grape stomping and wine blending workshops.",
  },

  "Cocktail Party": {
    small:
      "An intimate cocktail-making class with a mixologist, learning to craft signature cocktails and enjoying them in a private lounge setting.",
    medium:
      "A rooftop cocktail party with skyline views, featuring a mix-and-mingle atmosphere, live jazz music, and a selection of craft cocktails.",
    large:
      "A themed cocktail soiree at a rented venue with custom cocktails, cocktail stations, flair bartenders, and passed hors d'oeuvres.",
  },

  "Charity Event": {
    small:
      "A VIP charity gala dinner with celebrity guests, live entertainment, and a live auction of exclusive experiences and luxury items.",
    medium:
      "A charity walk or run event with a team fundraising goal, followed by a post-event celebration with food, drinks, and awards.",
    large:
      "A charity benefit concert featuring local bands and artists, with ticket sales and donations benefiting a chosen cause.",
  },

  "Networking Event": {
    small:
      "A one-on-one networking lunch with a mentor or industry leader, discussing career goals and opportunities.",
    medium:
      "A speed networking event with structured rounds of introductions and conversations, followed by open networking with refreshments.",
    large:
      "A mixer-style networking event with themed icebreakers, networking games, and interactive activities to facilitate connections.",
  },

  "Open Mic Night": {
    small:
      "A cozy evening at a local coffee shop or pub, enjoying live music and poetry readings by local artists and performers.",
    medium:
      "An open mic night event at a rented venue with a stage and sound system, featuring performances by comedians, musicians, and poets.",
    large:
      "An open mic showcase with multiple performance stages, art displays, and food vendors, celebrating local talent and creativity.",
  },

  "Comedy Show": {
    small:
      "A night of laughter at a comedy club with front-row seats, featuring stand-up performances by popular comedians and a two-drink minimum.",
    medium:
      "A private comedy show event with a lineup of local comedians, hosted at a rented venue with a comedy stage and seating for guests.",
    large:
      "A comedy festival event featuring headlining comedians, improv troupes, and sketch comedy performances, held at a theater or outdoor venue.",
  },

  "Masquerade Ball": {
    small:
      "A romantic masquerade ball at a historic mansion or castle, featuring ballroom dancing, live orchestra music, and candlelit dinner.",
    medium:
      "A themed masquerade party with elegant costumes, masks, and decorations, held at a rented venue with dancing, cocktails, and hors d'oeuvres.",
    large:
      "A grand masquerade ball fundraiser event with formal attire, live entertainment, silent auction, and a masquerade parade.",
  },

  "Murder Mystery": {
    small:
      "A private murder mystery dinner party at home, with a customized murder mystery game kit, costumes, and a gourmet dinner.",
    medium:
      "A murder mystery dinner theater event at a restaurant or rented venue, with interactive performances, clues, and a three-course meal.",
    large:
      "A large-scale murder mystery event with professional actors, multiple storylines, and themed decorations, held at a mansion or hotel.",
  },

  "Themed Party": {
    small:
      "A couples costume party at home with a chosen theme, costumes, themed decorations, and a playlist of themed music.",
    medium:
      "A themed costume contest event with categories, prizes, and a runway show, held at a rented venue with themed decor and photo booth.",
    large:
      "A themed costume ball fundraiser with live music, dancing, costume contests, and silent auction, held at an elegant venue.",
  },

  "Baby Gender Reveal": {
    small:
      "A private reveal moment with a gender reveal cake or balloons, captured on video for sharing with family and friends.",
    medium:
      "A gender reveal party with friends and family, featuring a creative reveal activity like a confetti cannon or smoke bombs, followed by a celebration.",
    large:
      "A gender reveal event with games, activities, and a grand reveal moment, held at a venue with themed decorations and refreshments.",
  },

  "Diwali Celebration": {
    small:
      "A private Diwali puja ceremony at home, followed by a traditional Indian dinner with homemade sweets and fireworks display.",
    medium:
      "A Diwali dinner party with friends and family, featuring Indian cuisine, music, and dance performances, and a rangoli-making competition.",
    large:
      "A community Diwali festival event with cultural performances, food stalls, henna artists, and a fireworks show.",
  },

  "Hanukkah Party": {
    small:
      "A cozy Hanukkah dinner at home with traditional Jewish dishes, menorah lighting, and exchanging of gifts.",
    medium:
      "A Hanukkah party with friends and family, featuring latkes, dreidel games, and a menorah lighting ceremony, followed by a potluck dinner.",
    large:
      "A community Hanukkah festival with a giant menorah lighting, live music, kosher food vendors, and Hanukkah crafts and activities for kids.",
  },

  "Easter Egg Hunt": {
    small:
      "A romantic Easter egg hunt with hidden love notes and small gifts leading to a surprise picnic or brunch location.",
    medium:
      "An Easter egg hunt event with themed stations, games, and prizes for participants of all ages, followed by a potluck picnic.",
    large:
      "A community Easter egg hunt with thousands of hidden eggs, face painting, bounce houses, and a visit from the Easter bunny.",
  },

  Graduation: {
    small:
      "A private graduation celebration with a gourmet dinner, champagne toast, and personalized gifts to commemorate the achievement.",
    medium:
      "A graduation party with friends and family, featuring a catered buffet, photo booth, and memory board with photos and messages.",
    large:
      "A graduation ceremony and reception event with speeches, awards, and a catered dinner, held at a venue with stage and seating.",
  },

  "Surprise Party": {
    small:
      "A surprise romantic dinner at a favorite restaurant with live music, followed by a surprise getaway to a luxury hotel.",
    medium:
      "A surprise party with friends and family at a rented venue, featuring themed decorations, games, and a special performance or presentation.",
    large:
      "A surprise birthday bash with a live band, dancing, photo booth, and a large-scale surprise reveal moment.",
  },

  "Farewell Party": {
    small:
      "A private farewell dinner at home with a slideshow of memories, heartfelt speeches, and a promise of future adventures together.",
    medium:
      "A farewell party with friends and colleagues at a favorite restaurant or bar, featuring a roast, farewell speeches, and a group toast.",
    large:
      "A farewell reception event with coworkers, clients, and community members, featuring a buffet dinner, networking, and a farewell presentation.",
  },

  "Welcome Home Party": {
    small:
      "A surprise welcome home celebration with balloons, banners, and a home-cooked meal waiting upon arrival.",
    medium:
      "A welcome home BBQ party with friends and family, featuring lawn games, a BBQ buffet, and a welcome home banner.",
    large:
      "A community welcome home event with a parade, speeches, and refreshments, celebrating the return of a local hero or military member.",
  },

  "Prom Night": {
    small:
      "A romantic prom night with a private dinner, limo ride, and dance under the stars, followed by a moonlit stroll.",
    medium:
      "A prom-themed party with friends, featuring a red carpet entrance, photo booth, and a DJ spinning hits from the high school years.",
    large:
      "A prom reunion event with alumni from multiple graduating classes, featuring a live band, dance floor, and prom king and queen contest.",
  },

  Anniversary: {
    small:
      "A romantic getaway to a secluded beach resort with candlelit dinners, couples massages, and a private sunset cruise.",
    medium:
      "An anniversary dinner party with friends and family at a rented venue, featuring a catered meal, speeches, and a slideshow of memories.",
    large:
      "A milestone anniversary celebration with a formal dinner dance, live band, and surprise anniversary video tribute.",
  },

  "Family Reunion": {
    small:
      "A private family dinner at a favorite restaurant, followed by a movie night with homemade popcorn and family photos.",
    medium:
      "A family reunion picnic at a local park with BBQ grilling, games, and a family talent show or trivia contest.",
    large:
      "A family reunion weekend retreat with accommodations, meals, and activities like hiking, swimming, and group games.",
  },

  "High School Reunion": {
    small:
      "A romantic weekend getaway coinciding with the high school reunion, with sightseeing, fine dining, and reminiscing about high school memories.",
    medium:
      "A reunion party at a rented venue with a DJ, photo booth, and class year-themed decorations, allowing classmates to reconnect and catch up.",
    large:
      "A multi-day reunion event with a variety of activities like a welcome reception, campus tour, gala dinner, and family-friendly picnic.",
  },

  "Promotion Party": {
    small:
      "A private celebration dinner at a upscale restaurant with champagne toasts, live music, and a special gift to commemorate the promotion.",
    medium:
      "A promotion party with coworkers and friends at a local bar or lounge, featuring cocktails, appetizers, and a toast to the honoree.",
    large:
      "A company-wide promotion celebration event with a catered dinner, speeches, and team-building activities to celebrate the achievements.",
  },

  "New Year's Eve": {
    small:
      "A romantic New Year's Eve getaway to a ski resort or cozy cabin with champagne, fireworks, and a midnight toast under the stars.",
    medium:
      "A New Year's Eve house party with friends and family, featuring a countdown, champagne toast, and fireworks viewing from the backyard.",
    large:
      "A New Year's Eve gala event with dinner, dancing, live entertainment, and a midnight balloon drop at a rented ballroom or hotel.",
  },

  "Valentine's Day": {
    small:
      "A romantic dinner at home with candlelight, roses, and a gourmet meal prepared together, followed by a couples massage.",
    medium:
      "A Valentine's Day singles mixer event with icebreaker games, speed dating, and a chocolate fondue station.",
    large:
      "A Valentine's Day masquerade ball with live music, dancing, and a best-dressed contest, held at a romantic venue.",
  },

  "Sweet 16 Party": {
    small:
      "A surprise sweet 16 celebration with a themed cake, balloons, and a small family dinner at home or a favorite restaurant.",
    medium:
      "A sweet 16 birthday bash with friends at a rented venue, featuring a DJ, dance floor, photo booth, and a candy buffet.",
    large:
      "A sweet 16 gala event with a formal dinner, live band, and themed decorations, celebrating the milestone birthday in style.",
  },

  Quinceañera: {
    small:
      "A traditional father-daughter dance at the quinceañera, followed by a formal dinner and presentation of the birthday girl.",
    medium:
      "A quinceañera celebration with family and friends at a banquet hall, featuring a choreographed dance, tiara ceremony, and a quinceañera waltz.",
    large:
      "A grand quinceañera fiesta with live music, dancing, and a lavish feast, held at a venue with elegant decor and lighting.",
  },

  "Sip and Paint Party": {
    small:
      "A private painting session for couples, with wine, cheese, and a professional art instructor guiding them through a romantic painting.",
    medium:
      "A sip and paint party with friends, featuring a guided painting session, wine tasting, and a gallery-style art display at the end.",
    large:
      "A large-scale sip and paint event with multiple painting stations, wine bars, and live music, held at an art gallery or event space.",
  },

  "Trivia Night": {
    small:
      "A cozy trivia night at a local pub, competing as a team and enjoying drinks and pub grub between rounds of questions.",
    medium:
      "A themed trivia night event with custom quiz rounds, prizes, and themed decorations, held at a rented venue with food and drinks.",
    large:
      "A trivia tournament event with teams competing in multiple rounds of trivia, hosted by a quizmaster, with prizes for the winners.",
  },

  "Escape Room Challenge": {
    small:
      "A private escape room experience for couples, with a custom storyline and puzzles tailored to their interests and abilities.",
    medium:
      "An escape room challenge event with multiple rooms and teams competing against each other to solve puzzles and escape first.",
    large:
      "An escape room marathon event with teams rotating through different rooms and challenges, followed by a celebration for all participants.",
  },

  "Spa Day": {
    small:
      "A couples spa retreat with massages, facials, and hydrotherapy treatments, followed by a gourmet spa lunch and relaxation time in a private suite.",
    medium:
      "A spa day outing with friends, featuring a variety of spa treatments, access to spa amenities like pools and saunas, and a healthy spa lunch.",
    large:
      "A spa party event with mini spa treatments, DIY facial and body scrub stations, healthy snacks, and relaxation areas with spa music and aromatherapy.",
  },

  "Music Festival": {
    small:
      "A VIP music festival experience with front-row seats, backstage access, and meet-and-greets with favorite artists, along with gourmet food and drinks.",
    medium:
      "A music festival camping trip with friends, featuring multiple days of live music, food trucks, and outdoor activities like hiking and swimming.",
    large:
      "A community music festival event with local bands and artists performing on multiple stages, along with food vendors, artisan vendors, and family-friendly activities.",
  },

  "Beer Tasting": {
    small:
      "A private beer tasting experience with a beer sommelier, featuring a selection of craft beers paired with artisanal cheeses and charcuterie.",
    medium:
      "A beer tasting tour to multiple breweries in a beer region, with transportation, brewery tours, and tastings of various beer styles.",
    large:
      "A beer festival event featuring local breweries, live music, food trucks, and activities like beer pong and cornhole tournaments.",
  },

  "Outdoor Concert": {
    small:
      "A romantic outdoor concert with a favorite band or artist, a picnic dinner, and dancing under the stars.",
    medium:
      "An outdoor concert tailgate party with friends, featuring BBQ grilling, lawn games, and a portable speaker for pre-show music.",
    large:
      "A community outdoor concert event with multiple bands or artists performing, food vendors, beer garden, and activities for all ages.",
  },

  "Poetry Slam": {
    small:
      "A cozy poetry reading at a local cafe, featuring romantic poetry and spoken word performances by local poets.",
    medium:
      "A poetry slam event with contestants competing for the title of best poet, judged by a panel of judges and audience participation.",
    large:
      "A community poetry festival with workshops, open mic sessions, and poetry readings by established and emerging poets, held at a park or cultural center.",
  },

  "Ice Cream Social": {
    small:
      "An intimate ice cream tasting in the backyard with a selection of homemade and gourmet ice cream flavors, toppings, and sauces, enjoyed in a cozy setting with your close friend.",
    medium:
      "An ice cream social gathering at a local park or community center, featuring multiple ice cream stations with a variety of flavors, toppings, and interactive activities like ice cream trivia or a sundae-making contest.",
    large:
      "An ice cream festival extravaganza in the town square, showcasing ice cream vendors from around the region offering unique flavors, creative toppings, and novelty treats, alongside live entertainment, carnival games, and ice cream-themed activities for people of all ages.",
  },

  "Wine and Cheese": {
    small:
      "A romantic wine and cheese tasting at home with a curated selection of wines, cheeses, and accompaniments, enjoyed by candlelight.",
    medium:
      "A wine and cheese pairing party with friends, featuring a variety of wines, cheeses, and charcuterie, along with tasting notes and pairings.",
    large:
      "A wine and cheese festival event with local wineries and cheesemakers offering tastings, seminars, and demonstrations, consider pairing this along with live music.",
  },

  "Board Game Marathon": {
    small:
      "A cozy board game night at home with favorite games, snacks, and drinks, playing into the early hours of the morning.",
    medium:
      "A board game marathon event with multiple game tables, featuring classic and modern board games, along with snacks and beverages.",
    large:
      "A board game convention event with tournaments, game demos, vendor booths, and panels, attracting board game enthusiasts of all ages.",
  },

  "Yoga Retreat": {
    small:
      "A private yoga retreat for couples at a secluded wellness resort, with daily yoga classes, meditation sessions, and spa treatments.",
    medium:
      "A yoga retreat getaway with friends, featuring daily yoga classes, guided hikes, healthy meals, and relaxation time by the pool.",
    large:
      "A community yoga retreat event with multiple yoga classes, workshops, and wellness activities, held at a retreat center or resort.",
  },

  "Beach Party": {
    small:
      "A romantic beach picnic with champagne, fresh seafood, and a sunset walk along the shore.",
    medium:
      "A beach bonfire party with friends, featuring roasted marshmallows, beach games, and a DJ spinning tunes under the stars.",
    large:
      "A beach festival event with live music, beach volleyball tournament, sandcastle competition, and food vendors.",
  },

  "Volunteer Day": {
    small:
      "A couples volunteer day at a local charity or shelter, helping with community service projects and making a positive impact together.",
    medium:
      "A group volunteer day with coworkers or friends, participating in a variety of volunteer activities like park clean-up, food bank sorting, or habitat restoration.",
    large:
      "A community volunteer event with multiple volunteer projects, organized by local organizations and charities, followed by a volunteer appreciation party.",
  },

  "Fundraising Gala": {
    small:
      "A VIP fundraising gala with a gourmet dinner, live entertainment, and an exclusive auction of luxury items and experiences.",
    medium:
      "A charity gala event with friends and colleagues, featuring a cocktail reception, silent auction, and live music or entertainment.",
    large:
      "A community fundraising gala with local businesses and organizations, featuring dinner, dancing, live performances, and a charity auction.",
  },

  "Food Truck Festival": {
    small:
      "A food truck date night with a variety of food trucks offering gourmet street food, live music, and outdoor seating under twinkling lights.",
    medium:
      "A food truck festival outing with friends, featuring a lineup of food trucks offering diverse cuisines, along with communal seating and lawn games.",
    large:
      "A food truck extravaganza event with dozens of food trucks, beer garden, live music, and family-friendly activities, attracting foodies of all ages.",
  },

  "Pet Adoption Event": {
    small:
      "A pet adoption day outing as a couple, visiting local shelters and rescue organizations to find the perfect furry companion to bring home.",
    medium:
      "A pet adoption fair with friends and family, featuring multiple rescue organizations, pet vendors, and activities like pet photo booths and agility demos.",
    large:
      "A community pet adoption event with multiple shelters and rescues, offering discounted adoption fees, pet care workshops, and family-friendly activities.",
  },

  "Jazz Night": {
    small:
      "A romantic jazz club date night with live music, cocktails, and dancing in an intimate and sophisticated atmosphere.",
    medium:
      "A jazz night outing with friends, featuring a jazz band performance at a local bar or lounge, with cocktails and small plates.",
    large:
      "A community jazz festival event with multiple stages, featuring jazz bands and artists of various styles and genres, along with food and drink vendors.",
  },

  "Artisan Market": {
    small:
      "A couples shopping day at an artisan market, browsing handmade crafts, jewelry, and gourmet foods, and supporting local artisans.",
    medium:
      "An artisan market outing with friends, featuring a variety of vendors selling handmade goods, artisanal foods, and unique gifts.",
    large:
      "A community artisan market event with dozens of local artisans and vendors, live music, food trucks, and interactive art demonstrations.",
  },

  Carnival: {
    small:
      "A romantic evening at the carnival with cotton candy, carnival games, and a ride on the Ferris wheel for a view of the twinkling lights.",
    medium:
      "A carnival outing with friends and family, featuring rides, games, circus performers, and carnival treats like funnel cakes and popcorn.",
    large:
      "A community carnival event with rides, games, live entertainment, food vendors, and a fireworks finale.",
  },

  "Fashion Runway": {
    small:
      "A front-row experience at a fashion runway show, featuring designer collections, celebrity sightings, and backstage access.",
    medium:
      "A fashion show viewing party with friends, featuring a live stream of a runway show, themed cocktails, and a red carpet photo booth.",
    large:
      "A community fashion runway event with local designers, models, and boutiques showcasing their latest collections, along with live music and vendor booths.",
  },

  "Film Festival": {
    small:
      "A romantic date night at a film festival, featuring indie films, Q&A sessions with filmmakers, and VIP access to after-parties.",
    medium:
      "A film festival viewing party with friends, featuring a curated selection of films, themed decorations, and film-inspired cocktails and snacks.",
    large:
      "A community film festival event with screenings of feature films, documentaries, and short films, along with filmmaker panels and workshops.",
  },

  "Science Fair": {
    small:
      "A couples science fair day, exploring interactive exhibits, hands-on experiments, and science demonstrations at a local science center or museum.",
    medium:
      "A science fair outing with friends and family, featuring student projects, science demonstrations, and STEM activities for all ages.",
    large:
      "A community science fair event with exhibits from local schools, universities, and STEM organizations, along with science-themed performances and workshops.",
  },

  "Talent Show": {
    small:
      "A talent show date night at a local venue, featuring performances by local artists, musicians, and comedians.",
    medium:
      "A talent show party with friends and family, featuring performances by guests of all ages, along with voting and prizes for the best acts.",
    large:
      "A community talent show event with contestants competing in multiple categories, judged by a panel of judges and audience participation.",
  },

  "TED Talk Event": {
    small:
      "A TED talk viewing party for couples, featuring a curated selection of TED talks on topics of interest, followed by discussions and reflections.",
    medium:
      "A TED talk event with friends, featuring live stream viewing of TED talks, discussions, and activities inspired by the talks.",
    large:
      "A community TEDx event with live speakers, TED talk screenings, workshops, and networking opportunities for attendees.",
  },

  "Cook-off Competition": {
    small:
      "A cooking competition date night at home, with a mystery ingredient challenge, timed rounds, and judging based on creativity and taste.",
    medium:
      "A cook-off competition party with friends and family, featuring teams competing in categories like chili, BBQ, or dessert, judged by a panel of judges.",
    large:
      "A community cook-off event with amateur and professional chefs competing in multiple categories, along with tastings, demos, and food vendors.",
  },

  "Sailing Excursion": {
    small:
      "A romantic sunset sailing cruise for two, with champagne, hors d'oeuvres, and breathtaking views of the coastline.",
    medium:
      "A sailing excursion with friends and family, featuring a chartered yacht, snorkeling, swimming, and a beach BBQ lunch on a secluded island.",
    large:
      "A sailing regatta event with multiple boats racing in a friendly competition, followed by a dockside party with food, drinks, and awards.",
  },

  "Outdoor Adventure": {
    small:
      "A romantic outdoor adventure getaway, with activities like hiking, kayaking, and zip-lining, followed by a cozy campfire dinner under the stars.",
    medium:
      "An outdoor adventure trip with friends, featuring camping, whitewater rafting, and hiking in a national park or wilderness area.",
    large:
      "A community outdoor adventure event with guided hikes, nature walks, rock climbing, and outdoor skills workshops for participants of all ages.",
  },

  "Surfing Lesson": {
    small:
      "A couples surfing lesson at a local beach, with personalized instruction, surfboards, and wetsuits provided, followed by a beachside picnic.",
    medium:
      "A surfing lesson outing with friends, featuring group instruction, surfboards, and wetsuit rentals, followed by beach games and a BBQ.",
    large:
      "A community surfing event with surf lessons for beginners, surf competitions for experienced surfers, and beach activities like volleyball and sandcastle building.",
  },

  "Cooking Class": {
    small:
      "A private cooking class with a celebrity chef, learning to prepare a gourmet meal together followed by a romantic dinner.",
    medium:
      "A hands-on cooking class with a professional chef at a cooking school, focusing on a specific cuisine or culinary technique, followed by a communal meal.",
    large:
      "A cooking competition event with teams competing in a series of cooking challenges judged by a panel of chefs, followed by a tasting and awards ceremony.",
  },

  "Ski Trip": {
    small:
      "A romantic ski getaway to a mountain resort, with skiing, snowboarding, and après-ski relaxation by the fireplace in a cozy cabin.",
    medium:
      "A ski trip with friends, featuring group lessons, ski rentals, and après-ski fun like hot tubbing, snowshoeing, and evening entertainment.",
    large:
      "A ski weekend event with accommodations, lift tickets, ski rentals, and group activities like ski races, snow tubing, and mountain dining.",
  },

  "Wine Tour": {
    small:
      "A private wine tour for couples, visiting multiple wineries, tasting rooms, and vineyards in a wine region, with transportation and gourmet lunch.",
    medium:
      "A wine tasting tour with friends, featuring a chauffeured bus or limo, tastings at select wineries, and a vineyard picnic lunch.",
    large:
      "A wine tour event with a guided tour of wine country, tastings at multiple wineries, gourmet meals, and entertainment.",
  },

  "Historical Tour": {
    small:
      "A romantic historical tour of a historic city or landmark, with a private guide, visits to museums and historic sites, and a candlelit dinner.",
    medium:
      "A historical tour outing with friends, featuring guided tours of historic neighborhoods, landmarks, and monuments, followed by a group dinner.",
    large:
      "A community historical tour event with guided tours, reenactments, and educational activities at historic sites and museums.",
  },

  "Baking Competition": {
    small:
      "A baking competition date night at home, with a mystery ingredient challenge, timed rounds, and judging based on creativity and taste.",
    medium:
      "A baking competition party with friends and family, featuring teams competing in categories like cakes, pies, or cookies, judged by a panel of judges.",
    large:
      "A community baking competition event with amateur and professional bakers competing in multiple categories, along with tastings, demos, and bake sales.",
  },

  "Book Club Meeting": {
    small:
      "A cozy book club meeting at home, discussing a chosen book over wine and cheese, followed by a movie adaptation screening.",
    medium:
      "A book club gathering with friends, featuring a discussion of the month's book selection, themed snacks, and book-related games or activities.",
    large:
      "A community book club event with guest authors, book signings, and discussions of featured books, held at a local bookstore or library.",
  },

  "Hiking Expedition": {
    small:
      "A romantic hiking adventure for couples, exploring scenic trails, picnicking at a waterfall, and stargazing under the night sky.",
    medium:
      "A hiking expedition with friends, featuring guided hikes to breathtaking viewpoints, wildlife sightings, and group camping under the stars.",
    large:
      "A community hiking event with multiple trail options for all skill levels, guided nature walks, and educational programs on local flora and fauna.",
  },

  "Gardening Party": {
    small:
      "A couples gardening day at home, planting flowers or herbs together, followed by a garden-to-table dinner using fresh herbs and vegetables.",
    medium:
      "A gardening party with friends, featuring a communal gardening project like planting a community garden or creating flower arrangements, followed by a garden party.",
    large:
      "A community gardening event with volunteers planting trees, flowers, or vegetables in public spaces like parks or school gardens, followed by a garden-themed festival.",
  },

  "Fishing Trip": {
    small:
      "A romantic fishing excursion for couples, chartering a boat for deep-sea fishing, followed by a seafood dinner prepared with the day's catch.",
    medium:
      "A fishing trip with friends, featuring a day of fishing on a lake or river, followed by a fish fry picnic and storytelling around a campfire.",
    large:
      "A community fishing event with fishing tournaments, casting competitions, and family-friendly activities like fish-themed crafts and games.",
  },

  "Karaoke Night": {
    small:
      "A private karaoke room rental with a selection of favorite songs, cocktails, and a karaoke host to serenade each other.",
    medium:
      "A karaoke bar takeover with reserved seating, group karaoke challenges, and themed costumes or props.",
    large:
      "A karaoke contest event with multiple rounds, judges, and prizes, held at a venue with a stage and professional sound system.",
  },

  "Dance Party": {
    small:
      "A private dance lesson with a professional instructor, learning a choreographed routine to a favorite song followed by a candlelit dinner.",
    medium:
      "A dance party at a nightclub or rented venue with a DJ, dance floor, and light show, featuring a mix of current hits and classic dance tunes.",
    large:
      "A themed dance extravaganza with live bands, DJs, dance performances, and interactive dance lessons, held at a large event space.",
  },

  "Fashion Show": {
    small:
      "A front-row experience at a high-fashion runway show, followed by a backstage tour and meeting with the designers.",
    medium:
      "A private fashion show featuring local designers and models, with a cocktail reception, runway seating, and VIP gift bags.",
    large:
      "A charity fashion gala event with runway shows, silent auctions, celebrity guests, and red carpet photo opportunities.",
  },

  "Crafting Party": {
    small:
      "A DIY crafting session at home, creating personalized keepsakes or home decor items together over wine and snacks.",
    medium:
      "A crafting workshop with a local artisan, learning to make seasonal crafts or DIY projects, accompanied by refreshments and music.",
    large:
      "A crafting fair event with multiple crafting stations, vendors, and demonstrations, where guests can make and take home their creations.",
  },

  "Pool Party": {
    small:
      "A private poolside retreat at a luxury resort with cabana service, couples massages, and a gourmet poolside dinner.",
    medium:
      "A backyard pool party with inflatable floats, tropical cocktails, BBQ grilling, and water games like volleyball and Marco Polo.",
    large:
      "A poolside bash at a rented venue or hotel with DJ entertainment, themed decorations, poolside bars, and a variety of water activities.",
  },

  BBQ: {
    small:
      "A romantic BBQ dinner for two in the backyard with grilled steak, seafood, and vegetables, accompanied by wine and candlelight.",
    medium:
      "A backyard BBQ cookout with a variety of grilled meats, sides, and desserts, along with lawn games and a DIY s'mores station.",
    large:
      "A community BBQ event with multiple grill stations, BBQ competitions, live music, and activities for all ages.",
  },

  "Tailgate Party": {
    small:
      "A tailgate picnic for two before a sporting event, featuring gourmet sandwiches, snacks, and beverages from the comfort of the car trunk.",
    medium:
      "A tailgate party with friends before a big game, featuring BBQ grilling, team spirit gear, and lawn games like cornhole and ladder toss.",
    large:
      "A stadium tailgate event with a large group of fans, featuring tents, food trucks, live music, and a pre-game pep rally.",
  },

  "Board Game Night": {
    small:
      "A cozy board game night at home with favorite games, snacks, and drinks, playing into the early hours of the morning.",
    medium:
      "A board game marathon event with multiple game tables, featuring classic and modern board games, along with snacks and beverages.",
    large:
      "A board game convention event with tournaments, game demos, vendor booths, and panels, attracting board game enthusiasts of all ages.",
  },

  "Housewarming Party": {
    small:
      "A private housewarming celebration for couples at their new home, featuring a champagne toast, tour of the house, and dinner by candlelight.",
    medium:
      "A housewarming party with friends and family, featuring a BBQ cookout, house tours, and a DIY housewarming gift exchange.",
    large:
      "A community housewarming event with neighbors and local residents, featuring food trucks, live music, and a raffle of gift baskets donated by local businesses.",
  },

  "Potluck Dinner": {
    small:
      "A potluck dinner for two with dishes prepared by each person, creating a diverse and delicious meal to share together.",
    medium:
      "A potluck dinner party with friends and family, featuring a variety of homemade dishes, shared recipes, and communal dining.",
    large:
      "A community potluck event with neighbors and local residents, featuring a diverse array of dishes, live music, and outdoor seating.",
  },

  Picnic: {
    small:
      "A romantic picnic for two in a scenic park, with a gourmet picnic basket, blanket, and wine, enjoying the beauty of nature together.",
    medium:
      "A picnic outing with friends and family, featuring a potluck picnic with everyone contributing a dish, along with games and activities like frisbee and soccer.",
    large:
      "A community picnic event with food trucks, live music, games, and activities for all ages, held in a local park or recreation area.",
  },

  "Themed Party": {
    small:
      "A themed date night at home with costumes, decorations, and activities based on a chosen theme, creating a fun and memorable experience together.",
    medium:
      "A themed costume party with friends, featuring themed decorations, costumes, music, and games, transporting guests to another world or time period.",
    large:
      "A large-scale themed event with immersive decor, themed entertainment, interactive activities, and costume contests, creating an unforgettable experience for attendees.",
  },

  "Cooking Competition": {
    small:
      "A cooking competition date night at home, with a mystery ingredient challenge, timed rounds, and judging based on creativity and taste.",
    medium:
      "A cooking competition party with friends and family, featuring teams competing in categories like appetizers, entrees, or desserts, judged by a panel of judges.",
    large:
      "A community cooking competition event with amateur and professional chefs competing in multiple categories, along with tastings, demos, and food vendors.",
  },

  "Birthday Party": {
    small:
      "A romantic birthday celebration for two with a candlelit dinner, champagne toast, and a surprise gift or experience.",
    medium:
      "A birthday party with friends and family, featuring a themed cake, decorations, games, and a piñata or photo booth for entertainment.",
    large:
      "A milestone birthday bash with a live band, dancing, photo booth, and a large-scale surprise reveal moment.",
  },
};
