import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTextField from "./CustomTextField.tsx";
import { eventTypes } from "./data/EventTypes.js";
import { RefreshContainer } from "./RefreshContainer.tsx";
import { useMediaQuery } from "@mui/material";

const EventSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:500px)");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [displayedEvents, setDisplayedEvents] = useState<
    { name: string; subQuestions: Record<string, boolean> }[]
  >([]);

  useEffect(() => {
    setDisplayedEvents(eventTypes.slice(0, isMobile ? 3 : 6));
  }, []);

  const handleRefreshClick = () => {
    const startIndex = Math.floor(Math.random() * (eventTypes.length - 3));
    const newEvents = eventTypes.slice(
      startIndex,
      startIndex + (isMobile ? 3 : 6)
    );
    setDisplayedEvents(newEvents);
  };

  const handleEventClick = (selectedEvent) => {
    setSelectedEventType(selectedEvent);
  };

  const nextRoute = () => {
    if (selectedEventType) {
      const remainingSubQuestions = selectedEventType?.subQuestions; // Keep it as an object
      const firstSubQuestion = Object.keys(remainingSubQuestions).find(
        (key) => remainingSubQuestions[key]
      );
      if (firstSubQuestion) {
        navigate(
          `/event-selection/${firstSubQuestion.toString().toLocaleLowerCase()}`,
          {
            state: {
              remainingSubQuestions: remainingSubQuestions, // Pass it directly as an object
              selectedEventType: selectedEventType,
              partySize: location.state?.partySize ?? "",
            },
          }
        );
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filteredEvents = eventTypes.filter((event) =>
      event.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDisplayedEvents(filteredEvents.slice(0, isMobile ? 3 : 6));
  };

  return (
    <Container className="container">
      <Grid
        item
        xs={12}
        className="centered-content"
        style={{ marginTop: "90px" }}
      >
        <Typography className="typography-1">
          Use the search to find your event, or choose from some options below.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="centered-content"
        style={{ marginTop: "30px" }}
      >
        <CustomTextField
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
        />
      </Grid>
      <Grid
        container
        spacing={5}
        className="centered-content"
        style={{ minHeight: "30vh", flexDirection: "column", padding: "10px" }}
      >
        <Grid
          item
          xs={12}
          className="centered-content"
          style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
        >
          {displayedEvents.map((event, index) => (
            <Button
              key={index}
              variant="contained"
              color={selectedEventType === event ? "secondary" : "primary"}
              className="button-1"
              onClick={() => handleEventClick(event)}
              style={{
                backgroundColor:
                  selectedEventType === event
                    ? "rgba(156, 125, 125, 2)"
                    : "inherit",
              }}
            >
              {event.name}
            </Button>
          ))}
        </Grid>
      </Grid>
      <RefreshContainer
        handleRefresh={handleRefreshClick}
        nextRoute={nextRoute}
        options={selectedEventType}
      />
    </Container>
  );
};

export default EventSelection;
