import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import { amazonItems } from "./data/amazonItems.js";
import { eventConcepts } from "./data/eventConcepts.js";

const ItemSelection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state: locationState } = useLocation();
  const [generatedText, setGeneratedText] = useState(
    locationState.generatedText ?? ""
  );
  const navigate = useNavigate();
  const [cart, setCart] = useState(locationState.cart ?? []);
  const eventName = locationState.selectedEventType?.name || "";
  const [startIndex, setStartIndex] = useState(0);
  const [buttonStatus, setButtonStatus] = useState(false);
  const isMobile = useMediaQuery("(max-width:500px)");
  const [error, setError] = useState(false);
  // const [options, setOptions] = useState([]);
  const [startIndices, setStartIndices] = useState({
    decorationOptions: 0,
    foodOptions: 0,
    colorOptions: 0,
    interestOptions: 0,
    beverageOptions: 0,
    cateringOptions: 0,
    dessertOptions: 0,
    seatingOptions: 0,
    interactiveOptions: 0,
    personalityOptions: 0,
    atmosphereOptions: 0,
    partyFavorOptions: 0,
  });

  useEffect(() => {
    if (generatedText) {
      document.body.style.overflowY = "visible";
    }
    return () => {
      document.body.style.overflowY = "hidden";
    };
  }, [generatedText]);

  const handleButtonClick = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      const eventConcept = generateEventConcept(
        locationState.selectedEventType,
        locationState.partySize,
        locationState.selectedEffects
      );
      setGeneratedText(eventConcept);
    }, 3000);
  };

  const generateEventConcept = (selectedEventType, partySize) => {
    if (!selectedEventType || !eventConcepts[eventName]) {
      setError(true);
      return `Sorry, we couldn't generate a concept for your event. Please try again.`;
    }

    let eventSize;
    if (partySize === 2) {
      eventSize = "small";
    } else if (partySize > 2 && partySize <= 30) {
      eventSize = "medium";
    } else {
      eventSize = "large";
    }

    const eventConcept = eventConcepts[eventName][eventSize];

    if (!eventConcept) {
      return `Sorry, we couldn't generate a concept for your event. Please try again.`;
      setError(true);
    }

    return eventConcept;
  };

  const handleBack = () => {
    navigate("/generate-concept", {
      state: {
        ...locationState,
      },
    });
  };

  const addToCart = (item) => {
    setButtonStatus(true);
    setTimeout(() => {
      setButtonStatus(false);
    }, 1000);
    if (cart.length > 10) {
      return;
    }
    setCart([...cart, item]);
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  // optionType == "dessertOptions" ||
  //   (optionType == "partyFavorOptions" && selectedOptions.includes("No"));

  const options = Object.entries(locationState.groupedOptions).filter(
    ([optionType, selectedOptions]) =>
      (selectedOptions.length >= 1 ||
        optionType === locationState.selectedEventType.name) &&
      ![
        optionType === "dessertOptions" && selectedOptions.includes("No")
          ? "dessertOptions"
          : optionType === "interactiveOptions" &&
            selectedOptions.includes("None")
          ? "interactiveOptions"
          : "remainingSubQuestions",
        "selectedEventType",
        "partySize",
        "ageOptions",
        "colorOptions",
        "destinationOptions",
        "atmosphereOptions",
        "personalityOptions",
      ].includes(optionType)
  );

  const handleNext = (optionType) => {
    const maxIndex =
      (amazonItems[optionType] || []).length -
      (window.innerWidth < 1000 ? 4 : 6);
    setStartIndices((prevIndices) => ({
      ...prevIndices,
      [optionType]: Math.min(
        (prevIndices[optionType] || 0) + (window.innerWidth < 1000 ? 4 : 6),
        maxIndex
      ),
    }));
  };

  const handlePrevious = (optionType) => {
    setStartIndices((prevIndices) => ({
      ...prevIndices,
      [optionType]: Math.max(
        (prevIndices[optionType] || 0) - (window.innerWidth < 1000 ? 4 : 6),
        0
      ),
    }));
  };

  const handleReviewCart = () => {
    if (cart) {
      navigate("/item-selection/review-cart", {
        state: {
          ...locationState,
          generatedText,
          cart,
        },
      });
    } else {
      console.error("Cart is Empty");
    }
  };

  const noOptionsSelected = (optionType, selectedOptions) => {
    const itemCount = amazonItems[optionType].filter((item) =>
      selectedOptions.some((option) => item.tags.includes(option.option))
    );
    return itemCount.length < 1;
  };

  return (
    <Container
      className="container"
      style={{
        backgroundColor: generatedText ? "white" : "",
        borderRadius: generatedText ? "30px" : "",
        width: generatedText ? "100%" : "",
        height: generatedText ? "80%" : "",
        marginTop: generatedText ? "5%" : "0px",
        paddingLeft: generatedText ? "15%" : "",
        paddingRight: generatedText ? "15%" : "",
        border: generatedText ? "1px solid #c19797" : "",
      }}
    >
      <Grid
        container
        spacing={4}
        style={{
          minHeight: "30vh",
          marginTop: generatedText ? "0px" : "250px",
          paddingBottom: "10px",
        }}
      >
        <Grid item xs={12} className="centered-content">
          {generatedText && !error ? (
            <></>
          ) : (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  className="button-1"
                  onClick={handleButtonClick}
                  startIcon={
                    isLoading && (
                      <CircularProgress
                        size={20}
                        style={{ color: "#c19797" }}
                      />
                    )
                  }
                >
                  {isLoading ? "Generating..." : "Generate Concept"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <div className="centered-content">
            {/* <Typography className="typography-2">
              {JSON.stringify(locationState.groupedOptions)}
            </Typography> */}
            <Typography id="generatedText" className="typography-4">
              {generatedText &&
                `For your ${locationState.selectedEventType.name.toLowerCase()} we have created the following concept for you. `}
              {generatedText}
            </Typography>
          </div>
        </Grid>
        <Grid container justifyContent="center">
          {generatedText && !error && (
            <Typography
              className="typography-2"
              style={{
                fontSize: "16.5px",
                margin: "30px 10px 10px 10px",
              }}
            >
              We have also chosen some items for you based off your selections:
            </Typography>
          )}
        </Grid>
        {generatedText &&
          !error &&
          options.map(([optionType, selectedOptions]) => {
            if (noOptionsSelected(optionType, selectedOptions)) {
              return null;
            } else {
              return (
                <Grid item xs={12} key={optionType}>
                  <Grid container justifyContent="center">
                    <Button
                      className="button-7"
                      style={{ marginBottom: "25px" }}
                    >
                      {optionType.charAt(0).toUpperCase() +
                        optionType
                          .slice(1)
                          .toLowerCase()
                          .replace("options", "")}
                    </Button>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="scrollable-row"
                    style={{ marginBottom: "150px" }}
                  >
                    {(amazonItems[optionType] || [])
                      .filter((item) =>
                        selectedOptions.some((option) =>
                          item.tags.includes(option.option)
                        )
                      )
                      .slice(
                        startIndices[optionType] || 0,
                        startIndices[optionType] +
                          (window.innerWidth < 1000 ? 4 : 6)
                      )
                      .map((item, idx) => (
                        <Grid item key={idx} className="centered-item">
                          <div className="item-card smaller-item">
                            <img
                              src={item.image}
                              alt={"image"}
                              style={{
                                width: "100px",
                                height: "100px",
                                padding: "0px 0px 10px 0px",
                              }}
                            />
                            <Typography
                              className="typography-2"
                              style={{
                                padding: "0px 0px 10px 0px",
                              }}
                            >
                              {item.description.length > 10 && isMobile
                                ? item.description.slice(0, 35) + "..."
                                : item.description}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                disabled={buttonStatus}
                                className="button-2"
                                onClick={() => addToCart(item)}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    <Grid item xs={12} className="arrow-buttons-container">
                      <IconButton
                        className="button-6 arrow-left"
                        style={{ height: "50px" }}
                        onClick={() => handlePrevious(optionType)}
                        disabled={startIndices[optionType] === 0}
                      >
                        <ArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        className="button-6 arrow-right"
                        style={{ height: "50px" }}
                        onClick={() => handleNext(optionType)}
                        disabled={
                          startIndex + (window.innerWidth < 1000 ? 4 : 6) >=
                          (amazonItems[optionType] || []).length
                        }
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          })}
      </Grid>
      {generatedText && cart.length >= 1 && (
        <Grid
          container
          item
          xs={12}
          className="centered-content fixed-bottom"
          justifyContent="center"
        >
          <div className="cart-container">
            <div
              className="cart-items"
              style={{ overflowX: "visible", width: "90%" }}
            >
              {cart.map((item, index) => (
                <div className="cart-item" key={index}>
                  <img
                    className="item-image"
                    src={item.image}
                    alt={"image"}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div className="item-details">
                    <Typography
                      style={{ color: "#c19797" }}
                      className="typography-2"
                    >
                      {item.description.length > 10
                        ? item.description.slice(0, 10) + "..."
                        : item.description}
                    </Typography>
                  </div>
                  <IconButton onClick={() => removeFromCart(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="button-9"
            onClick={handleReviewCart}
            style={{ height: "30px", bottom: 0 }}
          >
            Review Cart
          </Button>
        </Grid>
      )}
    </Container>
  );
};

export default ItemSelection;
