
import GamesIcon from '@mui/icons-material/Games';

const colorOptions = [
    { 
      label: 'White', 
      icon: <GamesIcon />
    },
    { 
      label: 'Orange', 
      icon: <GamesIcon />
    },
    { 
      label: 'Yellow', 
      icon: <GamesIcon />
    },
    { 
      label: 'Cyan', 
      icon: <GamesIcon />
    },
    { 
      label: 'Green', 
      icon: <GamesIcon />
    },
    { 
      label: 'Blue', 
      icon: <GamesIcon />
    },
    { 
      label: 'Purple', 
      icon: <GamesIcon />
    },
    { 
      label: 'Red', 
      icon: <GamesIcon />
    },
    { 
      label: 'Pink', 
      icon: <GamesIcon />
    },
    { 
      label: 'Brown', 
      icon: <GamesIcon />
    },
    { 
      label: 'Gray', 
      icon: <GamesIcon />
    },
    { 
      label: 'Black', 
      icon: <GamesIcon />
    },
    { 
      label: 'Teal', 
      icon: <GamesIcon />
    },
    { 
      label: 'Magenta', 
      icon: <GamesIcon />
    },
    { 
      label: 'Lime', 
      icon: <GamesIcon />
    },
    { 
      label: 'Indigo', 
      icon: <GamesIcon />
    },
    { 
      label: 'Turquoise', 
      icon: <GamesIcon />
    },
    { 
      label: 'Beige', 
      icon: <GamesIcon />
    },
    { 
      label: 'Violet', 
      icon: <GamesIcon />
    },
    { 
      label: 'Maroon', 
      icon: <GamesIcon />
    },
    { 
      label: 'Olive', 
      icon: <GamesIcon />
    },
    { 
      label: 'Coral', 
      icon: <GamesIcon />
    },
    { 
      label: 'Navy', 
      icon: <GamesIcon />
    },
    { 
      label: 'Aquamarine', 
      icon: <GamesIcon />
    },
    { 
      label: 'Salmon', 
      icon: <GamesIcon />
    },
    { 
      label: 'Sky Blue', 
      icon: <GamesIcon />
    },
    { 
      label: 'Tan', 
      icon: <GamesIcon />
    },
    { 
      label: 'Peach', 
      icon: <GamesIcon />
    },
    { 
      label: 'Gold', 
      icon: <GamesIcon />
    },
    { 
      label: 'Silver', 
      icon: <GamesIcon />
    },
    {
      label: 'Rose Gold',
      icon: <GamesIcon />
    },
    {
      label: 'Emerald',
      icon: <GamesIcon />
    },
    {
      label: 'Sapphire',
      icon: <GamesIcon />
    },
    {
      label: 'Ruby',
      icon: <GamesIcon />
    },
    {
      label: 'Amethyst',
      icon: <GamesIcon />
    }
];


  export default colorOptions