const celebrationEvents = [
  "Birthday",
  "Engagement",
  "Anniversary",
  "Bachelor Party",
  "Bachelorette",
  "Surpise Party",
  "Farewell Party",
  "Welcome Home Party",
  "Prom Night",
];

export const amazonItems = {
  decorationOptions: [
    {
      name: "Rose Gold Birthday Decorations",
      image: "https://m.media-amazon.com/images/I/81DXhu6ONML._AC_SL1500_.jpg",
      description: "Rose gold birthday decorations",
      link: "https://amzn.to/4a7UJOA”",
      tags: ["Rose Gold", "Birthday", "Pink", "", ""],
    },
    {
      name: "Taco Decorations",
      image: "https://m.media-amazon.com/images/I/81s0fKzU-XL._AC_SL1500_.jpg",
      description: "Taco Bout it Decorations",
      link: "https://amzn.to/3PjYqbB",
      tags: ["Birthday", "", "", "", ""],
    },
    {
      name: "Sweet 1 Decorations",
      image: "https://m.media-amazon.com/images/I/91kTYU142EL._AC_SL1500_.jpg",
      description: "Sweet One Birthday",
      link: "https://amzn.to/43nfH9Q",
      tags: ["1", "Birthday", "", "", ""],
    },
    {
      name: "Inflatable Cactus Cooler",
      image: "https://m.media-amazon.com/images/I/71dXIIKJn5L._AC_SL1500_.jpg",
      description: "Cactus Cooler - Inflatable",
      link: "https://amzn.to/4adHRX5",
      tags: ["Green", "", "", "", ""],
    },
    {
      name: "Fiesta Decorations",
      image: "https://m.media-amazon.com/images/I/91CSkbAya9L._AC_SL1500_.jpg",
      description: "Fiesta Decorations - 40 pcs",
      link: "https://amzn.to/4aeMpMW",
      tags: ["", "", "", "", ""],
    },
    {
      name: "Gold Confetti Balloons",
      image: "https://m.media-amazon.com/images/I/71WadldOOzL._AC_SL1500_.jpg",
      description: "Gold confetti balloons - 50 pcs",
      link: "https://amzn.to/3wZPBxH",
      tags: ["Chic", "Bachelorette", "Gold", "Housewarming", ""],
    },
    {
      name: "Cowgirl Bachelorette Decorations",
      image: "https://m.media-amazon.com/images/I/71TJZTmplUL._AC_SL1200_.jpg",
      description: "Lets go girls cowgirl bachelorette decorations",
      link: "https://amzn.to/3wYSFdg",
      tags: ["Rustic Charm", "Bachelorette", "Western", "", ""],
    },
    {
      name: "Camp Bachelorette Backdrop",
      image: "https://m.media-amazon.com/images/I/718LE1+lSyL._AC_SL1500_.jpg",
      description: "Camp Bachelorette Backdrop - pink/orange colour scheme",
      link: "https://amzn.to/3THaTZw",
      tags: ["Bachelorette", "", "", "", ""],
    },
    {
      name: "Let’s Get Toasted Sign",
      image: "https://m.media-amazon.com/images/I/71ihc6-O51L._SL1500_.jpg",
      description: "Camp Bach - Lets get toasted sign",
      link: "https://amzn.to/43oyEsB",
      tags: ["Bachelorette", "", "", "", ""],
    },
    {
      name: "Bridal Shower Rings Diamond Engagement Rings Faux",
      image: "https://m.media-amazon.com/images/I/71dOyk+BswL._AC_SX679_.jpg",
      description: "Bridal Shower Rings Diamond Engagement Rings Faux",
      link: "https://amzn.to/43vWiDx",
      tags: ["Engagement", "", "", "", ""],
    },
    {
      name: "Engagement Wedding Decoration",
      image: "https://m.media-amazon.com/images/I/71V5nIszzGL._AC_SL1500_.jpg",
      description: "Engagement Wedding Decoration",
      link: "https://amzn.to/3IO19GN",
      tags: ["Engagement", "", "", "", ""],
    },
    {
      name: "Gold Engagement Party Decorations,",
      image: "https://m.media-amazon.com/images/I/71gq9tg-7fL._AC_SL1500_.jpg",
      description: "Gold Engagement Party Decorations,",
      link: "https://amzn.to/43K7fBT",
      tags: ["Engagement", "", "", "", ""],
    },
    {
      name: "Engagement Wine Labels",
      image: "https://m.media-amazon.com/images/I/610PveK5DbL._AC_SL1200_.jpg",
      description: "Engagement Wine Labels",
      link: "https://amzn.to/3IPUrjq",
      tags: ["Engagement", "", "", "", ""],
    },
    {
      name: "Metallic Tinsel Foil Fringe Curtains Wedding for Birthday",
      image: "https://m.media-amazon.com/images/I/81yY4XGis+L._AC_SL1500_.jpg",
      description: "Metallic Tinsel Foil Fringe Curtains Wedding for Birthday",
      link: "https://amzn.to/3IO1jxT",
      tags: ["Rose Gold", "Pink", "Birthday", "Engagement", ""],
    },
    {
      name: "52Ft White and Gold Love Heart Garland Hanging Paper",
      image: "https://m.media-amazon.com/images/I/81QLJVv+XgL._AC_SL1500_.jpg",
      description: "52Ft White and Gold Love Heart Garland Hanging Paper",
      link: "https://amzn.to/3IUbK2N",
      tags: ["Engagement", "Gold", "Anniversary", "", ""],
    },
    {
      name: "",
      image: "https://m.media-amazon.com/images/I/61JU-O9DmVS._AC_SL1500_.jpg",
      description: "",
      link: "https://amzn.to/3IPsb0m",
      tags: ["Anniversary", "", "", "", ""],
    },
    {
      name: "Anniversary Balloon Banner + Balloon ",
      image: "https://m.media-amazon.com/images/I/61JU-O9DmVS._AC_SL1500_.jpg",
      description: "Anniversary Balloon Banner + Balloon",
      link: "https://amzn.to/3PuNDLO",
      tags: ["Anniversary", "Gold", "", "", ""],
    },
    {
      name: "Jumbo Inflatable Giant Dice",
      image: "https://m.media-amazon.com/images/I/61fDcWY4KFL._AC_SL1003_.jpg",
      description: "Jumbo Inflatable Giant Dice",
      link: "https://amzn.to/43xOukL",
      tags: ["Game Night", "Movie Night", "", "", ""],
    },
    {
      name: "Las Vegas Casino Photo Booth Props",
      image:
        "https://m.media-amazon.com/images/I/81WiH3AvRbS.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Las Vegas Casino Photo Booth Props",
      link: "https://amzn.to/3vnSFmE",
      tags: ["Movie Night", "Game Night", "", "", ""],
    },
    {
      name: "Video Game Birthday Decorations",
      image: "https://m.media-amazon.com/images/I/81UoRK-fW9L._AC_SL1500_.jpg",
      description: "Video Game Birthday Decorations",
      link: "https://amzn.to/3TLERvt",
      tags: ["Game Night", "", "", "", ""],
    },
    {
      name: "Gamer Wall Stickers",
      image: "https://m.media-amazon.com/images/I/71Z2s4FZ1dL._AC_SL1500_.jpg",
      description: "Gamer Wall Stickers",
      link: "https://amzn.to/3Vwv1Ps",
      tags: ["Game Night", "", "", "", ""],
    },
    {
      name: "Retirement Party Decorations",
      image:
        "https://m.media-amazon.com/images/I/71uhXrIB+ML._AC_SX300_SY300_.jpg",
      description: "Retirement Party Decorations",
      link: "https://amzn.to/3TwSPQv",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Retirement Decorations Kit",
      image:
        "https://m.media-amazon.com/images/I/41OxtHcbN4L._SY445_SX342_QL70_ML2_.jpg",
      description: "Retirement Decorations Kit",
      link: "Retirement Decorations Kit",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "We Will Miss You Banner",
      image:
        "https://m.media-amazon.com/images/I/61qgblYy4NL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "We Will Miss You Banner",
      link: "https://amzn.to/3TrjoH3",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Goodbye Tension Hello Pension ",
      image: "https://m.media-amazon.com/images/I/61mHw3A+LaL._AC_SL1500_.jpg",
      description: "Goodbye Tension Hello Pension ",
      link: "https://amzn.to/4a6KjPh",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Retirement Party Decorations Gold",
      image: "https://m.media-amazon.com/images/I/81Hw77x2MeL._AC_SL1500_.jpg",
      description: "Retirement Party Decorations Gold",
      link: "https://amzn.to/4a6QOBI",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Happy Retirement Party Decorations",
      image: "https://m.media-amazon.com/images/I/81hps6lWOCL._AC_SL1500_.jpg",
      description: "Happy Retirement Party Decorations",
      link: "https://amzn.to/3vvpsGk",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Foil Helium Bottle Balloons",
      image: "https://m.media-amazon.com/images/I/61Uhs32lazL._AC_SL1000_.jpg",
      description: "Foil Helium Bottle Balloons",
      link: "https://amzn.to/4csdRIU",
      tags: celebrationEvents,
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  partyFavorOptions: [
    {
      name: "Camp Bach Fanny Packs",
      image: "https://m.media-amazon.com/images/I/81QJoljh-4L._AC_SL1500_.jpg",
      description: "Camp Bach Packs - 7 pcs",
      link: "https://amzn.to/43ruTCH",
      tags: ["Bachelorette", "Yes", "", "", ""],
    },
    {
      name: "Baseball Party Favors",
      image: "https://m.media-amazon.com/images/I/91YcQbROJuL._AC_SL1500_.jpg",
      description: "Baseball Party Favors",
      link: "https://amzn.to/43sUmM5",
      tags: ["Baseball", "Yes", "", "", ""],
    },
    {
      name: "Camp Bach Hangover Kit",
      image: "https://m.media-amazon.com/images/I/61IqCyXmRlL._AC_SL1500_.jpg",
      description: "Party Favour/Hangover small drawstring bags - 10pcs",
      link: "https://amzn.to/3x3kzVB",
      tags: ["Bachelorette", "Yes", "", "", ""],
    },
    {
      name: "Bride Bucket Hat",
      image: "https://m.media-amazon.com/images/I/31DiVZ8f59L._AC_SX679_.jpg",
      description: "Camp Bach ‘bride’ bucket hat",
      link: "https://amzn.to/4ahgVFQ",
      tags: ["Bachelorette", "Yes", "", "", ""],
    },
    {
      name: "Goodie Bags for Gamer Birthday Party",
      image: "https://m.media-amazon.com/images/I/81iI73vXIGL._AC_SL1500_.jpg",
      description: "Goodie Bags for Gamer Birthday Party",
      link: "https://amzn.to/3PBN0jJ",
      tags: ["Game Night", "Movie Night", "Birthday", "", ""],
    },
    {
      name: "Elderly and Awesome Glitter Sash",
      image:
        "https://m.media-amazon.com/images/I/61WMUrhlunL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Elderly and Awesome Glitter Sash",
      link: "https://amzn.to/3J9XvaB",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: "Retirement Card Box",
      image:
        "https://m.media-amazon.com/images/I/71qTPtFx8HL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Retirement Card Box",
      link: "https://amzn.to/3vo5cX9",
      tags: ["Retirement", "", "", "", ""],
    },
    {
      name: '"Groom" Bachelor Party Sash',
      image:
        "https://m.media-amazon.com/images/I/41euZ8IpvoL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: '"Groom" Bachelor Party Sash',
      link: "https://amzn.to/496U8M6",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
      ],
    },
    {
      name: '"Groomsman" Bachelor Party Sash',
      image: "https://m.media-amazon.com/images/I/3134PoqRTrL._AC_.jpg",
      description: '"Groomsman" Bachelor Party Sash',
      link: "https://amzn.to/4ans200",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
      ],
    },
    {
      name: '"Best Man" Bachelor Party Sash"',
      image: "https://m.media-amazon.com/images/I/51yzI5XEWmL._AC_SL1000_.jpg",
      description: '"Best Man" Bachelor Party Sash"',
      link: "https://amzn.to/3x9qkkF",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
      ],
    },
    {
      name: "Bulk Wedding Sunglasses for Team Groom",
      image: "https://m.media-amazon.com/images/I/61exuaN3OFL._AC_SX679_.jpg",
      description: "Bulk Wedding Sunglasses for Team Groom",
      link: "https://amzn.to/3VwjiAG",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
      ],
    },
    {
      name: "Bachelor Party Drawstring Bags",
      image: "https://m.media-amazon.com/images/I/61LdlpCDTyL._AC_SL1500_.jpg",
      description: "Bachelor Party Drawstring Bags",
      link: "https://amzn.to/4aoP0nA",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
      ],
    },
    {
      name: "Funny Housewarming Party",
      image: "https://m.media-amazon.com/images/I/81mT-+IyfEL._AC_SL1500_.jpg",
      description: "Funny Housewarming Party",
      link: "https://amzn.to/49an8m7",
      tags: ["Housewarming", "Birthday", "", "", ""],
    },
    {
      name: "Housewarming Sunflower Soap",
      image: "https://m.media-amazon.com/images/I/71ZL+YPRvgL._AC_SL1500_.jpg",
      description: "Housewarming Sunflower Soap",
      link: "https://amzn.to/49aOSa9",
      tags: ["Housewarming", "", "", "", ""],
    },
    {
      name: "36Pcs Key Bottle Opener",
      image: "https://m.media-amazon.com/images/I/71p5FtPAUWL._AC_SL1000_.jpg",
      description: "36Pcs Key Bottle Opener",
      link: "https://amzn.to/43xYieQ",
      tags: ["Housewarming", "Bachelor Party", "Bachelorette", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  seatingOptions: [
    {
      name: "Picnic Placements",
      image: "https://m.media-amazon.com/images/I/91GHXYnAx2L._AC_SL1500_.jpg",
      description: "Picnic boho placemats",
      link: "https://amzn.to/3TjCuPk",
      tags: ["Picnic Tables", "Engagement", "", "", ""],
    },
    {
      name: "Wine Picnic Table",
      image: "https://m.media-amazon.com/images/I/81dyHHIiZQS._AC_SL1500_.jpg",
      description: "Portable Wine Picnic Table",
      link: "https://amzn.to/3Vl6vAD",
      tags: ["Picnic Tables", "Engagement", "", "", ""],
    },
    {
      name: "Lace Tablecloth",
      image: "https://m.media-amazon.com/images/I/51B2Aqw07KL._AC_.jpg",
      description: "White lace tablecloth",
      link: "https://amzn.to/4ciE0d9",
      tags: ["Picnic Tables", "Engagement", "Housewarming", "", ""],
    },
    {
      name: "Theater Cinema Theme Pillow Cases",
      image:
        "https://m.media-amazon.com/images/I/71TFYaxfFeS.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Theater Cinema Theme Pillow Cases",
      link: "https://amzn.to/4a3tCEB",
      tags: ["Movie Night", "Game Night", "", "", ""],
    },
    {
      name: "Polyester Round Table Cloth Sage Green",
      image: "https://m.media-amazon.com/images/I/61OIWavA1LL._AC_SL1500_.jpg",
      description: "Polyester Round Table Cloth Sage Green",
      link: "https://amzn.to/4a7MfqU",
      tags: ["Housewarming", "", "", "", ""],
    },
    {
      name: "Kitchen Spandex Tablecloth 2 Pack",
      image: "https://m.media-amazon.com/images/I/51fpjWXnIhL._AC_SL1500_.jpg",
      description: "Kitchen Spandex Tablecloth 2 Pack",
      link: "https://amzn.to/3x8almM",
      tags: ["Housewarming", "", "", "", ""],
    },
    {
      name: "Clear Table Protector",
      image: "https://m.media-amazon.com/images/I/71Yw1Tl86SL._AC_SL1500_.jpg",
      description: " Clear Table Protector,",
      link: "Clear Table Protector",
      tags: ["Housewarming", "", "", "", ""],
    },
    {
      name: "Waterproof Dining Table Cloth",
      image: "https://m.media-amazon.com/images/I/81zosrgPcEL._AC_SL1500_.jpg",
      description: "Waterproof Dining Table Cloth",
      link: "https://amzn.to/4ai5rCc",
      tags: ["Housewarming", "", "", "", ""],
    },
    {
      name: "8 Pack Red Round Tablecloth ",
      image: "https://m.media-amazon.com/images/I/61C8yd21EEL._AC_SL1500_.jpg",
      description: "8 Pack Red Round Tablecloth ",
      link: "https://m.media-amazon.com/images/I/81zosrgPcEL._AC_SL1500_.jpg",
      tags: ["Housewarming", "Game Night", "Movie Night", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  colorOptions: [
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  interestOptions: [
    {
      name: "21 Pcs Golf Cake Topper",
      image: "https://m.media-amazon.com/images/I/71lhRnwmi9L._AC_SL1500_.jpg",
      description: "21 Pcs Golf Cake Topper",
      link: "https://amzn.to/3IUbktf",
      tags: ["Golf", "Birthday", "", "", ""],
    },
    {
      name: "Football Party Decorations",
      image: "https://m.media-amazon.com/images/I/71xBLPgkUwL._AC_SL1500_.jpg",
      description: "Football Party Decorations",
      link: "https://amzn.to/498Feoz",
      tags: ["Soccer", "Football", "", "", ""],
    },
    {
      name: "Soccer Birthday Party Decorations",
      image: "https://m.media-amazon.com/images/I/91Z+R3xpPhL._AC_SL1500_.jpg",
      description: "Soccer Birthday Party Decorations",
      link: "https://amzn.to/4a1JOWY",
      tags: ["Soccer", "Football", "Birthday", "Green", ""],
    },
    {
      name: "Basketball Balloons",
      image:
        "https://m.media-amazon.com/images/I/91K3U8L2FtL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Basketball Balloons",
      link: "https://amzn.to/43vnoLl",
      tags: ["Basketball", "", "", "", ""],
    },
    {
      name: "Basketball Cupcake Toppers",
      image: "https://m.media-amazon.com/images/I/81fSwNbL+7L._AC_SL1500_.jpg",
      description: "Basketball Cupcake Toppers",
      link: "https://amzn.to/4a5h0g0",
      tags: ["Basketball", "", "", "", ""],
    },
    {
      name: "Basketball Happy Birthday Cake Toppers",
      image: "https://m.media-amazon.com/images/I/81NDHMpXe2L._AC_SL1500_.jpg",
      description: "Basketball Happy Birthday Cake Toppers",
      link: "https://amzn.to/4a5h0g0",
      tags: ["Basketball", "Birthday", "", "", ""],
    },
    {
      name: "Baseball Party Favors",
      image: "https://m.media-amazon.com/images/I/91YcQbROJuL._AC_SL1500_.jpg",
      description: "Baseball Party Favors",
      link: "https://amzn.to/43sUmM5",
      tags: ["Baseball", "", "", "", ""],
    },
    {
      name: "Glitter Baseball Cupcake Toppers",
      image:
        "https://m.media-amazon.com/images/I/61yCMwdQABL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "Glitter Baseball Cupcake Toppers",
      link: "https://amzn.to/3TOVIxL",
      tags: ["Baseball", "", "", "", ""],
    },
    {
      name: "Swimming Cupcake Toppers",
      image: "https://m.media-amazon.com/images/I/81SMeWm55dL._AC_SL1500_.jpg",
      description: " Swimming Cupcake Toppers",
      link: "https://amzn.to/43vIPM5",
      tags: ["Swimming", "", "", "", ""],
    },
    {
      name: "A Happy Camper Card Banners",
      image: "https://m.media-amazon.com/images/I/610X0bolLUL._SL1500_.jpg",
      description: "A Happy Camper Card Banners",
      link: "https://amzn.to/43vyz6C",
      tags: ["Hiking", "", "", "", ""],
    },
    {
      name: "Tennis Party Supplies Packs",
      image: "https://m.media-amazon.com/images/I/81Vx+EfjZKL._AC_SL1500_.jpg",
      description: "Tennis Party Supplies Packs",
      link: "https://amzn.to/3VvIFm8",
      tags: ["Tennis", "", "", "", ""],
    },
    {
      name: "Yoga Girl Colorful Mandala Flower Waterproof",
      image: "https://m.media-amazon.com/images/I/8131FfqBvWL._AC_SL1200_.jpg",
      description: "Yoga Girl Colorful Mandala Flower Waterproof",
      link: "https://amzn.to/3TLIwcP",
      tags: ["Yoga", "", "", "", ""],
    },
    {
      name: "Chef Hats For Kids",
      image: "https://m.media-amazon.com/images/I/41xR7ALRSUL._AC_SX679_.jpg",
      description: "Chef Hats For Kids",
      link: "https://amzn.to/49ccRFL",
      tags: ["Baking", "Cooking", "", "", ""],
    },
    {
      name: "Reading Sticker Pack",
      image: "https://m.media-amazon.com/images/I/71hojP-UnEL._AC_SL1000_.jpg",
      description: "Reading Sticker Pack",
      link: "https://amzn.to/3VtQecR",
      tags: ["Reading", "Writing", "", "", ""],
    },
    {
      name: "Acrylic Paint Set, 12 Vibrant Colors",
      image:
        "https://m.media-amazon.com/images/I/71nZRVY5UZL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: " Acrylic Paint Set, 12 Vibrant Colors",
      link: "https://amzn.to/3VteY4P",
      tags: ["Painting", "Housewarming", "", "", ""],
    },
    {
      name: "Acrylic Paint, Set of 24 Colors",
      image: "https://m.media-amazon.com/images/I/81XSxrV7wSL._AC_SL1500_.jpg",
      description: "Acrylic Paint, Set of 24 Colors",
      link: "https://amzn.to/3Vuq0XD",
      tags: ["Painting", "Housewarming", "", "", ""],
    },
    {
      name: '10" Ring Light with 50" Extendable Tripod Stand',
      image:
        "https://m.media-amazon.com/images/I/61-947b9ZDL.__AC_SX300_SY300_QL70_ML2_.jpg",
      description: "10'' Ring Light with 50'' Extendable Tripod Stand",
      link: "https://amzn.to/4aqaXT2",
      tags: ["Photography", "", "", "", ""],
    },
    {
      name: "Movie Theme Party Decorations Supplies",
      image: "https://m.media-amazon.com/images/I/81L3EK29OlS._AC_SL1500_.jpg",
      description: "Movie Theme Party Decorations Supplies",
      link: "https://amzn.to/4apAUCo",
      tags: ["Movie Night", "Acting", "Movie", "", "", "Game Night"],
    },
    {
      name: "Video Game Birthday Decorations",
      image: "https://m.media-amazon.com/images/I/81UoRK-fW9L._AC_SL1500_.jpg",
      description: "Video Game Birthday Decorations",
      link: "https://amzn.to/3TLERvt",
      tags: ["Gaming", "Video Games", "Gamer", "", ""],
    },
    {
      name: "Gamer Wall Stickers",
      image: "https://m.media-amazon.com/images/I/71Z2s4FZ1dL._AC_SL1500_.jpg",
      description: "Gamer Wall Stickers",
      link: "https://amzn.to/3Vwv1Ps",
      tags: ["Gaming", "Video Games", "Gamer", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  beverageOptions: [
    {
      name: "Tebery 100 Pack Gold Rimmed Plastic Cups",
      image: "https://m.media-amazon.com/images/I/71UHNWs-zmL._AC_SL1500_.jpg",
      description: "100 Pack Gold Rimmed Plastic Cups",
      link: "https://amzn.to/3VoE3xZ",
      tags: [
        "Cocktails",
        "Beer",
        "Game Night",
        "Bachelor Party",
        "Housewarming",
      ],
    },
    {
      name: "Plastic Martini Glasses",
      image: "https://m.media-amazon.com/images/I/71Tf1gbMuML._AC_SL1500_.jpg",
      description: "48PCS Plastic Martini Glasses",
      link: "https://amzn.to/43jDzLD",
      tags: ["Wine", "Champagne", "Cocktails", "Housewarming", ""],
    },
    {
      name: "Tebery 20 Pack Plastic Ribbed Wine Glasses",
      image:
        "https://m.media-amazon.com/images/I/81+5snNhcHL._AC_SY300_SX300_.jpg",
      description: "Tebery 20 Pack 14oz Plastic Ribbed Wine Glasses",
      link: "https://amzn.to/3wZOhed",
      tags: [
        "Wine",
        "Champagne",
        "Cocktails",
        "Bachelor Party",
        "Housewarming",
      ],
    },
    {
      name: "JoyJolt Faye Highball Glasses Set",
      image: "https://m.media-amazon.com/images/I/91M-G7zRkkL._AC_SL1500_.jpg",
      description: "6 Pack Highball Drinking Glasses - 13oz Glass Set",
      link: "https://amzn.to/3TFsNvT",
      tags: [
        "Wine",
        "Champagne",
        "Cocktails",
        "Bachelor Party",
        "Housewarming",
      ],
    },
    {
      name: "100pc Clear Shot Cups, 1oz/30ml",
      image: "https://m.media-amazon.com/images/I/71j5suVM+BL._AC_SL1500_.jpg",
      description: "100PCS Crystal Clear Shot Cups Shot Glasses",
      link: "https://amzn.to/3TE9Cmb",
      tags: [
        "Wine",
        "Champagne",
        "Cocktails",
        "Bachelor Party",
        "Housewarming",
      ],
    },
    {
      name: "MATANA 50 Gold Glitter Plastic Wine Glasses",
      image: "https://m.media-amazon.com/images/I/81uVSbxMaIL._AC_SL1500_.jpg",
      description: "MATANA 50 Gold Glitter Plastic Wine Glasses",
      link: "https://amzn.to/3IJJgZI",
      tags: ["Wine", "Champagne", "Cocktails", "Game Night", "Housewarming"],
    },
    {
      name: "Hobnail Drinking Glasses Set of 6, 12oz Vintage Glassware",
      image: "https://m.media-amazon.com/images/I/91XDS0E3WVL._AC_SL1500_.jpg",
      description: "KMIGRUAN Hobnail Glasses Set of 6, 12oz Vintage Glassware",
      link: "https://amzn.to/4cosDAc",
      tags: ["Wine", "Champagne", "Cocktails", "Engagement", "Bachelor Party"],
    },
    {
      name: "JoyJolt Drinking Glass Cups",
      image: "https://m.media-amazon.com/images/I/81Hb-Gd3niL._AC_SL1500_.jpg",
      description:
        "JoyJolt Drinking Glass Cups Set of 6 - 16oz Beer Can Glasses",
      link: "https://amzn.to/4amOaI1",
      tags: ["Wine", "Champagne", "Cocktails", "Game Night", "Bachelor Party"],
    },
    {
      name: "Glass Coffee Cups",
      image: "https://m.media-amazon.com/images/I/71BggM6FddL._AC_SL1000_.jpg",
      description: "Vintage glass coffee cups - set of 4",
      link: "https://www.amazon.ca/dp/B0BNVCK9Y3?starsLeft=1&th=1&linkCode=sl1&tag=lifesaparty0b-20&linkId=e1a417c8722c935b3b1607438891a3b0&language=en_CA&ref_=as_li_ss_tl",
      tags: ["Tea", "Coffee", "No Booze", "", ""],
    },
    {
      name: "Floral Tea Cups",
      image: "https://m.media-amazon.com/images/I/71FkIDzhQ6L._AC_SL1500_.jpg",
      description: "Porcelain floral tea cups - set of 6",
      link: "https://amzn.to/3PnFAk7",
      tags: ["Tea", "Coffee", "No Booze", "Housewarming", ""],
    },
    {
      name: "Pink Teapot",
      image: "https://m.media-amazon.com/images/I/510OeJUzIsL._AC_SL1001_.jpg",
      description: "Simple pink teapot ",
      link: "https://amzn.to/4aks88u",
      tags: ["Tea", "Coffee", "No Booze", "Housewarming", ""],
    },
    {
      name: "White Teapot",
      image: "https://m.media-amazon.com/images/I/91KGM6uNAqL._AC_SL1500_.jpg",
      description: "white porcelain tea pot",
      link: "https://amzn.to/3PnFAk7",
      tags: ["Tea", "Coffee", "No Booze", "Housewarming", ""],
    },
    {
      name: "Cowgirl Bachelorette Cups",
      image: "https://m.media-amazon.com/images/I/61znDDvjtJL._AC_SL1500_.jpg",
      description: "Pink ‘let’s go girls’ cups - 16 pcs",
      link: "https://amzn.to/3VuXWDo",
      tags: ["Wine", "Beer", "Champagne", "Bachelorette", ""],
    },
    {
      name: "Cowgirl Bachelorette Drink Pouches",
      image: "https://m.media-amazon.com/images/I/51jQ8FnuqQL._AC_SL1000_.jpg",
      description: "Drink pouches - 16pcs",
      link: "https://amzn.to/3Tqycp4",
      tags: ["Wine", "Beer", "Champagne", "Bachelorette", ""],
    },
    {
      name: "",
      image: "https://amzn.to/3PnFAk7",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  cateringOptions: [
    {
      name: "Chafing Dish Buffet Set",
      image: "https://m.media-amazon.com/images/I/71wFQN+P79L._AC_SL1500_.jpg",
      description: "Chafing Dish Buffet Set - 6 Pack of Food Warmers",
      link: "https://amzn.to/4ahjt70",
      tags: ["Family Style", "Buffet Style", "Barbecue", "Housewarming", ""],
    },
    {
      name: "ALPHA LIVING 70014-GRAY 4 Pack 8QT Chafing Dish High Grade Stainless Steel",
      image: "https://m.media-amazon.com/images/I/618n7kegjML._AC_SL1500_.jpg",
      description: "4 Pack 8QT Chafing Dish High Grade Stainless Steel",
      link: "https://amzn.to/493ilma",
      tags: ["Potluck", "Housewarming", "", "", ""],
    },
    {
      name: "Yarlung 8 Pack White Fast Food Trays",
      image: "https://m.media-amazon.com/images/I/61sNzXzTbfL._AC_SL1500_.jpg",
      description: 'Yarlung 8 Pack White Fast Food Trays, 16"x12"',
      link: "https://amzn.to/4afV81g",
      tags: ["Fast Food", "Brunch", "Housewarming", "", ""],
    },
    {
      name: "NATGAI Charcoal Grill Portable Barbecue Charcoal Grill",
      image: "https://m.media-amazon.com/images/I/71lq+CZG5OL._AC_SL1500_.jpg",
      description: "Stainless Steel Charcoal Grill",
      link: "https://amzn.to/4agyfKZ",
      tags: ["Family Style", "Buffet Style", "Barbecue", "Housewarming", ""],
    },
    {
      name: "Country Home: Slate Tapas Plate Set of 4",
      image: "https://m.media-amazon.com/images/I/71I3ogsrDsL._AC_SL1500_.jpg",
      description: "Country Home: Slate Tapas Plate Set of 4",
      link: "https://amzn.to/3TG1CRB",
      tags: [
        "Family Style",
        "Buffet Style",
        "Barbecue",
        "Dim Sum",
        "Housewarming",
      ],
    },
    {
      name: "SOUJOY 2 Pack Divided Serving Tray with Lid",
      image: "https://m.media-amazon.com/images/I/711Aeh1at8L._AC_SL1500_.jpg",
      description: '2 Pack 10" Divided Serving Tray with Lid',
      link: "https://amzn.to/4aijBmv",
      tags: ["Family Style", "Buffet Style", "Barbecue", "Housewarming", ""],
    },
    {
      name: "Wicker Picnic Basket",
      image: "https://m.media-amazon.com/images/I/81lxEmXZKfL._AC_SL1500_.jpg",
      description: "Wicker Picnic Basket for 4, 4 Person Picnic Kit",
      link: "https://amzn.to/3x31Edt",
      tags: ["Family Style", "Buffet Style", "Barbecue", "Engagement", ""],
    },
    {
      name: "Seamless Round Pizza Screen",
      image: "https://m.media-amazon.com/images/I/81ckVxaJqSL._AC_SL1500_.jpg",
      description:
        "4 Pack Seamless Round Pizza Screen, 2 Pcs 12 inch Aluminum Mesh",
      link: "https://amzn.to/3VhrF2O",
      tags: [
        "Family Style",
        "Buffet Style",
        "Barbecue",
        "Fast Food",
        "Pizza",
        "Finger Food",
        "Housewarming",
      ],
    },
    {
      name: "Bamboo Steamer 10 Inch, 2 Tier Steamer Basket & Lid",
      image: "https://m.media-amazon.com/images/I/61dhOQFSEHS._AC_SL1114_.jpg",
      description: "Bamboo Steamer 10 Inch, 2 Tier Steamer Basket & Lid,",
      link: "https://amzn.to/3x0wdQY",
      tags: [
        "Family Style",
        "Buffet Style",
        "Barbecue",
        "Fast Food",
        "Pizza",
        "Finger Food",
        "Engagement",
      ],
    },
    {
      name: "Drop Mini Appetizer Plates with Forks",
      image: "https://m.media-amazon.com/images/I/71dkwERwwXL._AC_SL1500_.jpg",
      description: "DLux 100 4-in Tear Drop Mini Appetizer Plates with Forks",
      link: "https://amzn.to/3vob2Yv",
      tags: [
        "Family Style",
        "Buffet Style",
        "Barbecue",
        "Fast Food",
        "Pizza",
        "Finger Food",
        "Engagement",
        "Housewarming",
      ],
    },
    {
      name: "Cocktail Picks, 4.7 Inch Toothpicks for Appetizers",
      image: "https://m.media-amazon.com/images/I/81lcBmS3TjL._AC_SL1500_.jpg",
      description: "Bamboo Cocktail Sticks Skewers for Drinks, Desserts",
      link: "https://amzn.to/48ZtBQH",
      tags: [
        "Family Style",
        "Buffet Style",
        "Barbecue",
        "Fast Food",
        "Pizza",
        "Finger Food",
      ],
    },
    {
      name: "Camp Bach Supplies",
      image: "https://m.media-amazon.com/images/I/81JNMctL+mL._AC_SL1500_.jpg",
      description:
        "Camp Bach plates, cups, napkins, cutlery - 120 pcs/24 guests",
      link: "https://amzn.to/3PnOSML",
      tags: ["Bachelorette", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  dessertOptions: [
    {
      name: "Cupcake Stand",
      image: "https://m.media-amazon.com/images/I/71LlxXuUx+L._AC_SL1500_.jpg",
      description: "Cupcake Stand",
      link: "https://amzn.to/3wX4WyV",
      tags: ["Yes", "", "", "", ""],
    },
    {
      name: "Mini Dessert Cups",
      image: "https://m.media-amazon.com/images/I/81i5ZycPcUL._AC_SL1500_.jpg",
      description: "DLux 100 x 3 oz Mini Dessert Cups",
      link: "https://amzn.to/3vgOmtj",
      tags: ["Yes", "", "", "", ""],
    },
    {
      name: "Dessert Table Display Set",
      image: "https://m.media-amazon.com/images/I/81gD5hzu1kL._AC_SL1500_.jpg",
      description: "HeiMma 3 Pack Clear Acrylic Dessert Stands Buffet Risers",
      link: "https://amzn.to/3TGdeE4",
      tags: ["Yes", "", "", "", ""],
    },
    {
      name: "Dessert Tongs",
      image: "https://m.media-amazon.com/images/I/61nhktyd1nL._AC_SL1500_.jpg",
      description: "Silver Dessert Tongs - set of 6",
      link: "https://amzn.to/3v5Ake8",
      tags: ["Yes", "", "", "", ""],
    },
    {
      name: "Floral Napkins",
      image: "https://m.media-amazon.com/images/I/81SQJq+oX-L._AC_SL1500_.jpg",
      description: "Pink floral napkins - 20 pack",
      link: "https://amzn.to/3v5Ake8",
      tags: ["Yes", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  interactiveOptions: [
    {
      name: "Polaroid Camera",
      image: "https://m.media-amazon.com/images/I/51yUlWvDcnL._AC_SL1500_.jpg",
      description: "Fujifilm instant mini polaroid camera",
      link: "https://amzn.to/49XqV7y",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Housewarming",
      ],
    },
    {
      name: "Square Polaroid Camera",
      image: "https://m.media-amazon.com/images/I/81znl2g7H2L._AC_SL1500_.jpg",
      description: "Square black polaroid camera",
      link: "https://amzn.to/3PsUBRw4",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
      ],
    },
    {
      name: "Disposable Camera",
      image: "https://m.media-amazon.com/images/I/513HVzCDz4L._AC_SL1500_.jpg",
      description: "Kodak funsaver disposable film camera",
      link: "https://amzn.to/3TIc1wi",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Engagement",
        "Game Night",
        "Bachelor Party",
        "Housewarming",
      ],
    },
    {
      name: "Heart Pencils",
      image: "https://m.media-amazon.com/images/I/71JUP1gAHaL._AC_SL1500_.jpg",
      description: "Box of pencils for games",
      link: "https://amzn.to/43mfdAK",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Game Night",
        "Pink",
      ],
    },
    {
      name: "Murder Mystery",
      image: "https://m.media-amazon.com/images/I/71+XZZfgk4L._AC_SL1134_.jpg",
      description: "Murder mystery interactive game - 5-12 people",
      link: "https://amzn.to/4ciEShV",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Game Night",
        "Housewarming",
      ],
    },
    {
      name: "Hot Seat",
      image: "https://m.media-amazon.com/images/I/61vABvrjuDL._AC_SL1500_.jpg",
      description: "All ages interactive game",
      link: "https://amzn.to/43vc6Xh",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Game Night",
        "Housewarming",
      ],
    },
    {
      name: "Bachelorette Bash Game",
      image: "https://m.media-amazon.com/images/I/71NRLe5sq0L._AC_SL1500_.jpg",
      description: "Interactive bachelorette party game",
      link: "https://amzn.to/43pICtH",
      tags: [
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Game Night",
        "Bachlorette",
      ],
    },
    {
      name: "Whisky Bottle Adult Pinata",
      image: "https://m.media-amazon.com/images/I/61E3RCYDMWL._AC_SL1500_.jpg",
      description: "Whisky Bottle Adult Pinata",
      link: "https://amzn.to/3PAbhGP",
      tags: [
        "Bachelor Party",
        "Yes",
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Housewarming",
      ],
    },
    {
      name: "Shot Glass Roulette Drinking Game Set",
      image: "https://m.media-amazon.com/images/I/71hUmEAiOOL._AC_SL1500_.jpg",
      description: "Shot Glass Roulette Drinking Game Set",
      link: "https://amzn.to/3TK0Q65",
      tags: [
        "Bachelor Party",
        "Game Night",
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Housewarming",
      ],
    },
    {
      name: "Hook and Ring Toss Game ",
      image: "https://m.media-amazon.com/images/I/61JK4lUVW7L._AC_SX679_.jpg",
      description: "Hook and Ring Toss Game ",
      link: "https://amzn.to/4cs89Xu",
      tags: [
        "Bachelor Party",
        "Yes",
        "Game Night",
        "",
        "",
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Housewarming",
      ],
    },
    {
      name: "Sotally Tober Drinking Games for Adults",
      image: "https://m.media-amazon.com/images/I/61Z6rLA-a2L._AC_SL1500_.jpg",
      description: "Sotally Tober Drinking Games for Adults",
      link: "https://amzn.to/3TLPYVq",
      tags: [
        "Bachelor Party",
        "Yes",
        "Game Night",
        "",
        "",
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Housewarming",
      ],
    },
    {
      name: "Post Malone, World Pong League Beer Pong Drinking Game",
      image: "https://m.media-amazon.com/images/I/81rycMOJ5NL._AC_SL1500_.jpg",
      description: "Post Malone, World Pong League Beer Pong Drinking Game",
      link: "https://amzn.to/3xi1WNu",
      tags: [
        "Bachelor Party",
        "Yes",
        "Game Night",
        "",
        "",
        "Little",
        "Moderate",
        "Mixed",
        "High",
        "Not Sure",
        "Housewarming",
      ],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  destinationOptions: [
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  personalityOptions: [
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
  atmosphereOptions: [
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
    {
      name: "",
      image: "",
      description: "",
      link: "",
      tags: ["", "", "", "", ""],
    },
  ],
};
