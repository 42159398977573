import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMediaQuery } from "@mui/material";

export const RefreshContainer = ({ nextRoute, handleRefresh, _ }) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const isTablet = useMediaQuery("(max-width:1000px)");
  const isDesktop = useMediaQuery("(min-width:1001px)");

  const determineDeviceSize = () => {
    if (isMobile) {
      return "5%";
    } else if (isTablet) {
      return "20%";
    } else if (isDesktop) {
      return "30%";
    }
    return "30%";
  };

  return (
    <div
      style={{
        position: "fixed",
        left: 0, // Align with the left edge of the screen
        bottom: determineDeviceSize(), // Adjust the position vertically
        width: "100%",
        zIndex: 999,
      }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <RefreshIcon
            onClick={handleRefresh}
            style={{ fontSize: "30px", color: "#c19797" }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className="button-2"
            onClick={nextRoute}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
