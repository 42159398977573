
import GamesIcon from '@mui/icons-material/Games';

const destinationOptions = [
    { 
      label: 'Beach Resort', 
      icon: <GamesIcon />
    },
    { 
      label: 'Mountain Lodge', 
      icon: <GamesIcon />
    },
    { 
      label: 'City Rooftop', 
      icon: <GamesIcon />
    },
    { 
      label: 'Countryside Retreat', 
      icon: <GamesIcon />
    },
    { 
      label: 'Luxury Yacht', 
      icon: <GamesIcon />
    },
    { 
      label: 'Historic Mansion', 
      icon: <GamesIcon />
    },
    { 
      label: 'Forest Cabin', 
      icon: <GamesIcon />
    },
    { 
      label: 'Island Hideaway', 
      icon: <GamesIcon />
    },
    { 
      label: 'Vineyard Estate', 
      icon: <GamesIcon />
    },
    { 
      label: 'Art Gallery', 
      icon: <GamesIcon />
    },
    { 
      label: 'Botanical Garden', 
      icon: <GamesIcon />
    },
    { 
      label: 'Urban Loft', 
      icon: <GamesIcon />
    },
    { 
      label: 'Ski Resort', 
      icon: <GamesIcon />
    },
    { 
      label: 'Desert Oasis', 
      icon: <GamesIcon />
    },
    { 
      label: 'Cruise Ship', 
      icon: <GamesIcon />
    },
    { 
      label: 'Waterfront Pier', 
      icon: <GamesIcon />
    },
    { 
      label: 'Country Club', 
      icon: <GamesIcon />
    },
    { 
      label: 'Rooftop Garden', 
      icon: <GamesIcon />
    },
    { 
      label: 'Historic Theater', 
      icon: <GamesIcon />
    },
    { 
      label: 'Sports Stadium', 
      icon: <GamesIcon />
    },
    { 
      label: 'Luxury Hotel', 
      icon: <GamesIcon />
    },
    { 
      label: 'Art Studio', 
      icon: <GamesIcon />
    },
    { 
      label: 'Boutique Winery', 
      icon: <GamesIcon />
    },
    { 
      label: 'Farmhouse Estate', 
      icon: <GamesIcon />
    },
    { 
      label: 'Seaside Cliffs', 
      icon: <GamesIcon />
    },
];


  export default destinationOptions