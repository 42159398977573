import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import beverageOptions from "./data/sub_questions/Beverages.js";
import { useLocation, useNavigate } from "react-router-dom";
import { RefreshContainer } from "./RefreshContainer.tsx";

const Beverages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allSelectedEffects, setAllSelectedEffects] = useState([]);
  const { selectedEventType } = location.state;
  const subQuestions = location.state?.remainingSubQuestions;
  const [selectedEffects, setSelectedEffects] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextRoute = () => {
    selectedEffects.push(location.state.selectedEventType.name);
    // Create a copy of subQuestions object
    const remainingSubQuestions = { ...subQuestions };

    // Remove the current key-value pair for the current component
    delete remainingSubQuestions.beverages;

    // Find the next true key and use its value for navigation
    const nextQuestion = Object.entries(remainingSubQuestions).find(
      ([key, value]) => value === true
    );

    if (nextQuestion) {
      const [nextKey] = nextQuestion;
      navigate(`/event-selection/${nextKey.toString().toLowerCase()}`, {
        state: {
          ...location.state,
          remainingSubQuestions: remainingSubQuestions,
          beverageOptions: selectedEffects,
        },
      });
    } else {
      navigate("/generate-concept", {
        state: {
          ...location.state,
          beverageOptions: selectedEffects,
        },
      });
    }
  };

  useEffect(() => {
    const startIndex = currentIndex * 9;
    const endIndex = startIndex + 9;
    setDisplayedOptions(effectOptions.slice(startIndex, endIndex));
  }, [currentIndex]);

  const handleRefreshClick = () => {
    setAllSelectedEffects((prevSelectedEffects) => [...prevSelectedEffects]);
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(effectOptions.length / 9)
    );
  };

  const effectOptions = beverageOptions;

  const handleEffectClick = (effectLabel) => {
    if (
      allSelectedEffects.length <= 9 ||
      allSelectedEffects.includes(effectLabel)
    ) {
      setAllSelectedEffects((prevSelectedEffects) => {
        if (prevSelectedEffects.includes(effectLabel)) {
          return prevSelectedEffects.filter((label) => label !== effectLabel);
        } else {
          return [...prevSelectedEffects, effectLabel];
        }
      });

      setSelectedEffects((prevSelectedEffects) => {
        if (prevSelectedEffects.includes(effectLabel)) {
          return prevSelectedEffects.filter((label) => label !== effectLabel);
        } else {
          return [...prevSelectedEffects, effectLabel];
        }
      });
    }
  };

  return (
    <Container className="container">
      <Typography style={{ paddingTop: "90px" }} className="typography-1">
        Choose the beverages you'll be serving at the{" "}
        {selectedEventType.name.toLowerCase()}.
      </Typography>
      <Grid
        container
        spacing={3}
        className="centered-content"
        style={{ padding: "5px", minHeight: "40vh", flexDirection: "column" }}
      >
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {displayedOptions.map((option) => (
              <Button
                key={option.label}
                variant="contained"
                color="primary"
                disabled={
                  selectedEffects.length >= 5 &&
                  !selectedEffects.includes(option.label)
                }
                className="button-1"
                style={{
                  flex: "1 0 30%",
                  width: "120px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: selectedEffects.includes(option.label) ? 5 : 2,
                  backgroundColor: selectedEffects.includes(option.label)
                    ? "rgba(160, 125, 125, 3)"
                    : "inherit",
                }}
                onClick={() => handleEffectClick(option.label)}
              >
                <ListItemIcon
                  style={{ color: "#c19797", justifyContent: "center" }}
                >
                  {option.icon}
                </ListItemIcon>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "0.6rem",
                  }}
                >
                  <span>{option.label}</span>
                </div>
              </Button>
            ))}
          </div>
        </Grid>
      </Grid>
      <RefreshContainer
        handleRefresh={handleRefreshClick}
        nextRoute={nextRoute}
        options={beverageOptions}
      />
    </Container>
  );
};

export default Beverages;
