import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const PageLayout = ({ children }) => {
  return (
    <Container className="container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        minHeight="100vh"
        paddingTop="20px" // FROM TOP
        marginBottom="20px" // FROM BOTTOM
      >
        {children}
      </Box>
    </Container>
  );
};

export default PageLayout;
