import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Age = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subQuestions = location.state?.remainingSubQuestions;
  const [age, setAge] = useState(1); // Initial age value

  const nextRoute = () => {
    // Create a copy of subQuestions object
    const remainingSubQuestions = { ...subQuestions };

    // Remove the current key-value pair for the current component
    delete remainingSubQuestions.age;

    // Find the next true key and use its value for navigation
    const nextQuestion = Object.entries(remainingSubQuestions).find(
      ([_, value]) => value === true
    );

    if (nextQuestion) {
      const [nextKey] = nextQuestion;
      navigate(`/event-selection/${nextKey.toString().toLowerCase()}`, {
        state: {
          ...location.state,
          remainingSubQuestions: remainingSubQuestions,
          ageOptions: age,
        },
      });
    } else {
      navigate("/generate-concept", {
        state: {
          ...location.state,
          ageOptions: age,
        },
      });
    }
  };

  return (
    <Container className="container">
      <Grid>
        <Typography style={{ paddingTop: "90px" }} className="typography-1">
          Finally, choose the age of the person you're celebrating.
        </Typography>
        <Grid item xs={12}>
          <Slider
            value={age}
            onChange={(event, newValue) => setAge(newValue)}
            aria-labelledby="age-slider"
            valueLabelDisplay="auto"
            step={1}
            className="slider-2"
            min={1}
            max={100}
            getAriaValueText={(value) => `${value} years`}
          />
        </Grid>
        <ToastContainer />
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="button-2"
            disabled={age < 0 || age > 100}
            onClick={nextRoute}
            style={{
              marginTop: "143px",
            }}
          >
            Continue with {age}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Age;
