import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
     navigate(`/`)
     }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Grid
      container
      className="container"
      justifyContent="center"
      alignItems="center"
      style={{ height: '50vh' }}
    >
      <Grid item xs={12} textAlign="center">
        <Typography variant="h3" gutterBottom className="typography-3">
          404 - Not Found
        </Typography>
        <Typography variant="body1" gutterBottom className="typography-1">
          The page you are looking for does not exist.
        </Typography>
        <Typography variant="body1" className="typography-2">
          Redirecting to the home page...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
