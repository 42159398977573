
import GamesIcon from '@mui/icons-material/Games';

const decorationOptions = [
    { 
      label: 'Vintage Chic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Rustic Charm', 
      icon: <GamesIcon />
    },
    { 
      label: 'Tropical Paradise', 
      icon: <GamesIcon />
    },
    { 
      label: 'Under the Sea', 
      icon: <GamesIcon />
    },
    { 
      label: 'Enchanted Forest', 
      icon: <GamesIcon />
    },
    { 
      label: 'Masquerade Ball', 
      icon: <GamesIcon />
    },
    { 
      label: 'Gatsby Glamour', 
      icon: <GamesIcon />
    },
    { 
      label: 'Winter Wonderland', 
      icon: <GamesIcon />
    },
    { 
      label: 'Hollywood Glam', 
      icon: <GamesIcon />
    },
    { 
      label: 'Bohemian Rhapsody', 
      icon: <GamesIcon />
    },
    { 
      label: 'Fairy Tale Fantasy', 
      icon: <GamesIcon />
    },
    { 
      label: 'Nautical Vibes', 
      icon: <GamesIcon />
    },
    { 
      label: 'Urban Chic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Country Cottage', 
      icon: <GamesIcon />
    },
    { 
      label: 'Circus Extravaganza', 
      icon: <GamesIcon />
    },
    { 
      label: 'Art Deco Elegance', 
      icon: <GamesIcon />
    },
    { 
      label: 'Fiesta Fun', 
      icon: <GamesIcon />
    },
    { 
      label: 'Garden Party', 
      icon: <GamesIcon />
    },
    { 
      label: 'Outer Space Odyssey', 
      icon: <GamesIcon />
    },
    { 
      label: 'Gothic Romance', 
      icon: <GamesIcon />
    },
    { 
      label: 'Wild West Adventure', 
      icon: <GamesIcon />
    },
    { 
      label: 'Casino Royale', 
      icon: <GamesIcon />
    },
    { 
      label: 'Mardi Gras Madness', 
      icon: <GamesIcon />
    },
    { 
      label: 'Carnival Celebration', 
      icon: <GamesIcon />
    },
    { 
      label: 'Garden Tea Party', 
      icon: <GamesIcon />
    },
    { 
      label: 'Whimsical Wonderland', 
      icon: <GamesIcon />
    },
    { 
      label: 'Parisian Chic', 
      icon: <GamesIcon />
    },
    { 
      label: 'Asian Fusion', 
      icon: <GamesIcon />
    },
    { 
      label: 'Prehistoric Party', 
      icon: <GamesIcon />
    },
    { 
      label: 'Egyptian Elegance', 
      icon: <GamesIcon />
    },
    { 
      label: 'Medieval Majesty', 
      icon: <GamesIcon />
    }
];


  export default decorationOptions