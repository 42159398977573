import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const [sliderValue, setSliderValue] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set a brief delay before setting isLoaded to true
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const handleButtonClick = () => {
    if (sliderValue > 0) {
      navigate('/event-selection', { state: { 
        selectedEffects: "",
        selectedEventType: "",
        partySize: sliderValue
      }});
    } else {
      toast.error('Please select more guests!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <Container className="container">
      <Grid container spacing={5} className="centered-content" style={{ minHeight: '85vh', flexDirection: "column" }}>
      <Grid item xs={12} className={`slide-up ${isLoaded && 'slide-up-active'}`}>
          <Typography className="typography-3">
          Let's create an event,
          </Typography>
        </Grid>
        <Grid item xs={12} className={`fade-in ${isLoaded && 'fade-in-active'}`}>
          <Typography className="typography-1">
            Can you start by telling us how many people will be attending?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            aria-labelledby="party-size-slider"
            valueLabelDisplay="auto"
            step={1}
            className="slider-1"
            min={1}
            max={100}
            getAriaValueText={(value) => `${value} guests`}
          />
        </Grid>
        <ToastContainer />
        <Grid item xs={10}>
          {/* { sliderValue > 1 ?  */}
            <Button
              onClick={() => handleButtonClick()}
              variant="contained"
              color="primary"
              className="button-4"
              >
            {sliderValue > 1 ? `Continue with ${sliderValue} guests` : `Continue`}
          </Button>
          {/* : <></>
          } */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
