import GamesIcon from "@mui/icons-material/Games";

const seatingOptions = [
  {
    label: "Couches",
    icon: <GamesIcon />,
  },
  {
    label: "Bar Stools",
    icon: <GamesIcon />,
  },
  {
    label: "Bean Bags",
    icon: <GamesIcon />,
  },
  {
    label: "Lounge Chairs",
    icon: <GamesIcon />,
  },
  {
    label: "Picnic Tables",
    icon: <GamesIcon />,
  },
  {
    label: "Benches",
    icon: <GamesIcon />,
  },
  {
    label: "Adirondack",
    icon: <GamesIcon />,
  },
  {
    label: "Folding Chairs",
    icon: <GamesIcon />,
  },
  {
    label: "Bistro Tables",
    icon: <GamesIcon />,
  },
  {
    label: "Hammocks",
    icon: <GamesIcon />,
  },
  {
    label: "Swing Seats",
    icon: <GamesIcon />,
  },
  {
    label: "Director Chairs",
    icon: <GamesIcon />,
  },
  {
    label: "Bleachers",
    icon: <GamesIcon />,
  },
  {
    label: "Rocking Chairs",
    icon: <GamesIcon />,
  },
  {
    label: "Floor Cushions",
    icon: <GamesIcon />,
  },
];
export default seatingOptions;
