
import GamesIcon from '@mui/icons-material/Games';

const interactiveOptions = [
    { 
      label: 'None', 
      icon: <GamesIcon />, 
    },
    { 
      label: 'Little', 
      icon: <GamesIcon />, 
    },
    { 
      label: 'Moderate', 
      icon: <GamesIcon />, 
    },
    { 
      label: 'Mixed', 
      icon: <GamesIcon />, 
    },
    { 
      label: 'High', 
      icon: <GamesIcon />, 
    },
    { 
      label: 'Not sure', 
      icon: <GamesIcon />, 
    },
  ]

  export default interactiveOptions