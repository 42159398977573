import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import HomeIcon from '@mui/icons-material/House';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// Component
const NavBar = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  // Styled components
  const StyledContainer = styled('div')({
    marginTop: '5px',
    paddingBottom: '20%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '10%',
    // zIndex: 1000, // Ensure it stays above other elements
  });

  // Override MUI Grid padding
  const StyledGrid = styled(Grid)({
    padding: 0,
  });

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <StyledContainer>
      <StyledGrid container>
        <Grid item>
        <IconButton style={{ color: '#c19797'}}
            component="a"
            href="https://www.instagram.com/lifesaparty.collective/"
            target="_blank"
            rel="noopener noreferrer"
            color="#ecbaba"
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton style={{ color: '#c19797'}}
            onClick={() => handleNavigate('/')}>
            <HomeIcon fontSize="large" />
          </IconButton>
        </Grid>
      </StyledGrid>
      <Dialog open={openDialog} onClose={handleCancel} className="dialogBox">
        <DialogTitle className="typography-1">
          Are you sure you wish to exit?
        </DialogTitle>
        <DialogActions>
          <Button className="button-1" onClick={handleCancel} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default NavBar;
