export const eventTypes = [
  {
    name: "Engagement",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Bachelor Party",
    subQuestions: {
      atmosphere: true,
      seating: false,
      catering: false,
      beverages: true,
      interests: false,
      colors: false,
      food: false,
      decorations: false,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Bachelorette",
    subQuestions: {
      atmosphere: true,
      seating: false,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: true,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Housewarming",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: true,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Retirement",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: false,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Game Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Movie Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Book Club",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Art Show",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine Tasting",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Cocktail Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Cooking Class",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Karaoke Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Dance Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Fashion Show",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Crafting Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Pool Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Picnic",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Charity Event",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Networking Event",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Open Mic Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Comedy Show",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Masquerade Ball",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Murder Mystery",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Themed Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Baby Gender Reveal",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: false,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Diwali Celebration",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Hanukkah Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Easter Egg Hunt",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Graduation",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: false,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: true,
    },
  },
  {
    name: "Surprise Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Farewell Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Welcome Home Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Prom Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Anniversary",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Family Reunion",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "High School Reunion",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Promotion Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "New Year's Eve",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Valentine's Day",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Sweet 16 Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Quinceañera",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
  {
    name: "Sip and Paint Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Trivia Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Escape Room",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Spa Day",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Music Festival",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Beer Tasting",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Outdoor Concert",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Poetry Slam",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine and Cheese",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Board Game Marathon",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: false,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Yoga Retreat",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Beach Party",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: false,
    },
  },
  {
    name: "Fundraising Gala",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Nature Hike",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine Tour",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Bonfire Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "TED Talk Event",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  // {
  //   name: "Food and Wine Pairing",
  //   subQuestions: {
  //     atmosphere: true,
  //     seating: true,
  //     catering: true,
  //     beverages: true,
  //     interests: true,
  //     colors: true,
  //     food: false,
  //     decorations: true,
  //     personality: false,
  //     destination: true,
  //     interactive: true,
  //     partyfavors: true,
  //     dessert: false,
  //     age: false,
  //   },
  // },
  {
    name: "Scavenger Hunt",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Poetry Reading",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Drum Circle",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "DIY Workshop",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Pet Costume Contest",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Gaming Tournament",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Talent Show",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Potluck Dinner",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Coffee Tasting",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Science Fair",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Tango Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine Auction",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Plant Swap",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Food Drive",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine and Paint",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  // {
  //   name: "Mixology Party",
  //   subQuestions: {
  //     atmosphere: true,
  //     seating: true,
  //     catering: true,
  //     beverages: true,
  //     interests: true,
  //     colors: true,
  //     food: false,
  //     decorations: true,
  //     personality: false,
  //     destination: true,
  //     interactive: true,
  //     partyfavors: true,
  //     dessert: false,
  //     age: false,
  //   },
  // },
  {
    name: "Tapas Night",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Poetry Workshop",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "DIY Spa Day",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Board Game Cafe",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Food Photography Workshop",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Wine and Chocolate",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Antique Fair",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  {
    name: "Magic Show",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: false,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: true,
      interactive: true,
      partyfavors: true,
      dessert: false,
      age: false,
    },
  },
  // {
  //   name: "Chocolate Workshop",
  //   subQuestions: {
  //     atmosphere: true,
  //     seating: true,
  //     catering: true,
  //     beverages: true,
  //     interests: true,
  //     colors: true,
  //     food: false,
  //     decorations: true,
  //     personality: false,
  //     destination: true,
  //     interactive: true,
  //     partyfavors: true,
  //     dessert: false,
  //     age: false,
  //   },
  // },
  // {
  //   name: "Street Photography",
  //   subQuestions: {
  //     atmosphere: true,
  //     seating: true,
  //     catering: false,
  //     beverages: true,
  //     interests: true,
  //     colors: true,
  //     food: false,
  //     decorations: true,
  //     personality: false,
  //     destination: true,
  //     interactive: true,
  //     partyfavors: true,
  //     dessert: false,
  //     age: false,
  //   },
  // },
  {
    name: "Birthday",
    subQuestions: {
      atmosphere: true,
      seating: true,
      catering: true,
      beverages: true,
      interests: true,
      colors: true,
      food: false,
      decorations: true,
      personality: false,
      destination: false,
      interactive: true,
      partyfavors: true,
      dessert: true,
      age: true,
    },
  },
];
